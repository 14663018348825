import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmButton,
  PageHeader,
  InputText,
  InputDate,
  Typography,
} from '../components';
import { theme } from '../theme';
import { addDays, addMonths, dateToString } from '../utils';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { seasonService } from '../services';
import { Warning } from '../components/common/Warning';

const StyledSeasonCreateWrapper = styled.div`
  margin: 24px 120px;
  max-width: 436px;
  width: 100%;
`;

const DatesWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 7px;
  & > div {
    width: 100%;
    &:first-child {
      margin-right: 16px;
    }
  }
`;

const today = new Date();

export const SeasonCreatePage = () => {
  const navigate = useNavigate();

  const { graySubtitle } = theme.colors;

  const [name, setName] = useState('');
  const [levels, setLevels] = useState('');
  const [startDate, setStartDate] = useState({ value: today, touched: false });
  const [endDate, setEndDate] = useState({
    value: addDays(today, 1),
    touched: false,
  });
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    const start = startDate.value.setMilliseconds(0);
    const end = endDate.value.setMilliseconds(0);
    if (start >= end) {
      setEndDate((prev) => ({
        ...prev,
        value: addDays(startDate.value, 1),
      }));
    } else if (startDate.value < addMonths(endDate.value, -2)) {
      setEndDate((prev) => ({
        ...prev,
        value: addMonths(startDate.value, 2),
      }));
    }
  }, [startDate, endDate.value]);

  const clearState = () => {
    setName('');
    setLevels('');
    setStartDate({ value: today, touched: false });
    setEndDate({ value: addDays(today, 1), touched: false });
  };

  const handleSeasonCreate = () =>
    new Promise<void>((resolve) => {
      setIsEnabled(false);
      const season = {
        endDate: dateToString(endDate.value),
        name,
        numberSeasonElements: +levels,
        startDate: dateToString(startDate.value),
      };

      seasonService
        .create(season)
        .then((response) => {
          if (response.status === 200) {
            resolve();
            clearState();
          }
        })
        .finally(() => setIsEnabled(true));
    });

  const isValid = () => !!name.length && !!levels.length && isEnabled;

  return (
    <StyledSeasonCreateWrapper>
      <Warning right />
      <PageHeader
        onClick={() => navigate('/gamification')}
        title="Создание сезона"
      />
      <InputText
        inputValue={name}
        setInputValue={setName}
        label="Название сезона"
        placeholder="Введите название сезона"
        mb={23}
      />
      <DatesWrapper>
        <InputDate
          min={today}
          date={startDate}
          setDate={setStartDate}
          label="Дата начала сезона"
        />
        <InputDate
          min={addDays(startDate.value, 1)}
          max={addMonths(startDate.value, 2)}
          date={endDate}
          setDate={setEndDate}
          label="Дата конца сезона"
        />
      </DatesWrapper>
      <Typography fz={12} lh={14} color={graySubtitle} mb={24}>
        Сезон может идти максимум 2 месяца
      </Typography>
      <InputText
        inputValue={levels}
        setInputValue={setLevels}
        label="Количество уровней в сезоне"
        placeholder="Введите количество уровней (не больше 30)"
        mb={23}
        max={30}
      />
      <ConfirmButton
        onClick={handleSeasonCreate}
        text="Создать сезон"
        mt={40}
        modalMessage="Сезон создан"
        modalBtnText="Хорошо"
        disable={!isValid()}
      />
      <QuestionLabel />
    </StyledSeasonCreateWrapper>
  );
};
