import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmButton,
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
  Typography,
  InputText,
  Task,
  InputSelect,
  SberXP,
} from '../components';
import cityMobil from '../assets/images/cityMobil.png';
import sberEda from '../assets/images/sberEda.png';
import sberSound from '../assets/images/sberSound.png';
import {
  productService,
  actionService,
  taskService,
  difficultyService,
} from '../services';
import { SelectData } from '../models';
import { theme } from '../theme';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';

const Title = styled.div`
  margin-bottom: 10px;
  font-family: 'SB Sans Text';
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
`;

const Box = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayBorderBox};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100%;
  width: 544px;
  border-radius: 8px;
  padding: 78px;
  margin-bottom: 40px;
  background-color: ${(props) => props.theme.colors.grayBg};
`;
const TaskActiveCheckbox = styled.div`
  display: flex;
  margin-bottom: 23px;
  input {
    cursor: pointer;
    margin-right: 10px;
  }
  label {
    margin-bottom: -2px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    margin-top: -4px;
  }
`;
const AchievementExample = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayBorderBox};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 4px;
  background-color: ${(props) => props.theme.colors.grayBg};
  padding: 78px;
  border-radius: 8px;
  width: 544px;
  min-height: 450px;
`;

const StyledPageRightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

const awardXp = [50, 200, 700, 1500];

export const TaskPage = () => {
  const {
    questionPurpleBg,
    questionDarkPurpleBg,
    questionRedBg,
    graySubtitle,
  } = theme.colors;
  const navigate = useNavigate();
  const isSuperAdmin = useCheckIsSuperAdmin();

  const [actionsData, setActionsData] = useState<SelectData[]>([]);
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [difficultyData, setDifficultyData] = useState<SelectData[]>([]);

  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [action, setAction] = useState({ id: '0', name: 'Выберите действие' });
  const [difficulty, setDifficulty] = useState({
    id: '0',
    name: 'Выберите сложность',
  });
  const [eventsCount, setEventsCount] = useState('');
  const [duration, setDuration] = useState('');
  const [nameInSmallCard, setNameInSmallCard] = useState('');
  const [description, setDescription] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [motivatingPrompt, setMotivatingPrompt] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [showMetodology, setShowMetodology] = useState(true);
  const [taskIsActive, setTaskIsActive] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
        disabled: el.id !== 4 && localStorage.getItem('isDevices') === 'true',
      }));

      setProductsData(products);
      if (!isSuperAdmin)
        setProduct(products[products.findIndex((el) => el.id === '1')]);

      const difficulties = (await difficultyService.getAll()).data;
      setDifficultyData(
        difficulties.map((el) => ({ id: String(el.id), name: el.name }))
      );
    }
    if (isSuperAdmin !== null) fetchData();
  }, [isSuperAdmin]);

  useEffect(() => {
    (async function fetchActions() {
      if (product.id === '0') return;
      try {
        const actions = (await actionService.getAll(product.id)).data;
        setActionsData(
          actions.map((el) => ({ id: el.actionId, name: el.name }))
        );
        setAction({ id: '0', name: 'Выберите действие' });
      } catch (e) {
        setActionsData([]);
      }
    })();
  }, [product]);

  useEffect(() => {
    if (product.id === '4') {
      setEventsCount('1');
      setDuration('9999999');
      setDifficulty({
        id: difficultyData[0].id,
        name: difficultyData[0].name,
      });
      setButtonLink('void');
      setButtonText('void');
      setTaskIsActive(true);
    }
  }, [product, difficultyData]);

  const clearState = () => {
    setProduct({ id: '0', name: 'Выберите продукт' });
    setAction({ id: '0', name: 'Выберите действие' });
    setDifficulty({
      id: '0',
      name: 'Выберите сложность',
    });
    setEventsCount('');
    setDuration('');
    setDescription('');
    setNameInSmallCard('');
    setMotivatingPrompt('');
    setButtonLink('');
    setButtonText('');
  };

  const handleHideMetodology = () =>
    setShowMetodology((prevState) => !prevState);

  const handleTaskCreate = () =>
    new Promise<void>((resolve) => {
      setIsEnabled(false);
      const task = {
        actionId: String(action.id),
        description,
        difficultyId: +difficulty.id,
        duration: +duration,
        actionsCount: +eventsCount,
        product: { id: +product.id, name: product.name },
        linkToTask: buttonLink,
        isActive: taskIsActive,
        buttonText,
        nameInSmallCard,
        motivatingPrompt,
      };

      taskService.create(task).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
          setIsEnabled(true);
        }
      });
    });

  const getActionName = () =>
    action.name.includes('N')
      ? action.name.replace('N', eventsCount)
      : action.name;

  const isValid = () =>
    product.id !== '0' &&
    action.id !== '0' &&
    difficulty.id !== '0' &&
    !!eventsCount.length &&
    !!buttonLink.length &&
    !!buttonText.length &&
    nameInSmallCard !== '' &&
    !!duration.length &&
    motivatingPrompt !== '' &&
    (!!description.length || !isSuperAdmin) &&
    isEnabled;

  return (
    <PageWrapper>
      <PageLeftHalf showMetodology={showMetodology}>
        <StyledPageLeftContent>
          <PageHeader
            onClick={() => navigate('/gamification')}
            title="Создание задания"
          />
          {isSuperAdmin && (
            <InputSelect
              data={productsData}
              inputValue={product}
              setInputValue={setProduct}
              label="Продукт"
              mb={23}
            />
          )}
          <InputSelect
            data={actionsData}
            inputValue={action}
            setInputValue={setAction}
            label="Действия"
            mb={23}
          />
          {product.id !== '4' && (
            <>
              <InputText
                inputValue={eventsCount}
                setInputValue={setEventsCount}
                label="Повторы"
                placeholder="Введите количество повторов"
                min={1}
                max={999999}
                mb={23}
              />
              <InputText
                inputValue={duration}
                setInputValue={setDuration}
                label="Срок жизни"
                placeholder="Введите срок жизни"
                min={1}
                max={1000}
                mb={23}
                hint="Считается в днях"
              />
              <InputSelect
                data={difficultyData}
                inputValue={difficulty}
                setInputValue={setDifficulty}
                label="Сложность"
                mb={7}
              />
            </>
          )}
          <InputText
            inputValue={description}
            setInputValue={setDescription}
            label="Название задания"
            placeholder="Введите название"
            multiple
            mb={23}
          />
          <InputText
            inputValue={nameInSmallCard}
            setInputValue={setNameInSmallCard}
            label="Название"
            placeholder="Введите название для маленькой карточки"
            mb={23}
          />

          <InputText
            inputValue={motivatingPrompt}
            setInputValue={setMotivatingPrompt}
            label="Подсказка"
            placeholder="Мотивирующая подсказка"
            mb={20}
          />
          {product.id !== '4' && (
            <>
              <InputText
                inputValue={buttonLink}
                setInputValue={setButtonLink}
                label="Ссылка для перехода по нажатию кнопки"
                placeholder="Введите ссылку для перехода"
                mb={23}
              />
              <InputText
                inputValue={buttonText}
                setInputValue={setButtonText}
                label="Название кнопки"
                placeholder="Введите название кнопки"
                mb={23}
              />
              <TaskActiveCheckbox>
                <input
                  id="isActive"
                  type="checkbox"
                  value={String(taskIsActive)}
                  onChange={() => setTaskIsActive((prev) => !prev)}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="isActive">Задание активно</label>
              </TaskActiveCheckbox>
            </>
          )}
          {isSuperAdmin && <SberXP currentXP="7200" levelXP="10000" mb={40} />}
          <ConfirmButton
            onClick={handleTaskCreate}
            text="Создать задание"
            modalMessage="Задание создано"
            modalBtnText="Хорошо"
            disable={!isValid()}
          />
        </StyledPageLeftContent>
      </PageLeftHalf>
      {showMetodology && (
        <PageRightHalf>
          <StyledPageRightContent>
            <Box>
              <Task
                questionImg={sberSound as string}
                title={action.id !== '0' ? getActionName() : ''}
                description={description}
                product={product.name}
                stepsDone="1"
                steps={eventsCount !== '' ? eventsCount : '2'}
                xp={awardXp[difficulty.id === '0' ? 0 : +difficulty.id - 1]}
                bg={questionPurpleBg}
                duration={+duration}
              />
            </Box>
            <Title>Хорошие примеры</Title>
            <AchievementExample>
              <Task
                questionImg={sberEda as string}
                title=""
                description="15 продаж продукта"
                product="Wellness"
                stepsDone="13"
                steps="25"
                xp={700}
                bg={questionRedBg}
                mb={4}
                duration={3}
              />
              <Task
                questionImg={cityMobil as string}
                title=""
                description="Прочитать 10 статей"
                product="СберАгент"
                stepsDone="3"
                steps="10"
                xp={200}
                bg={questionDarkPurpleBg}
                duration={5}
              />
            </AchievementExample>
          </StyledPageRightContent>
        </PageRightHalf>
      )}
      <QuestionLabel onClick={handleHideMetodology} />
    </PageWrapper>
  );
};
