import styled, { css } from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SearchIcon } from '../assets/icons';
import { InputControls, PageHeader } from '../components';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { specprojectService } from '../services';
import { getClaimDateFormat, stringToTimestamp } from '../utils';
import { Claim, ClaimField } from '../models';
import { Warning } from '../components/common/Warning';

const StyledClaimsWrapper = styled.div`
  margin: 10px 32px;
  width: 100%;
`;

const StyledSearchField = styled.div`
  position: relative;
  max-width: 360px;
  margin-bottom: 16px;
  & > svg {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  border-radius: 8px;
  tr {
    color: ${(props) => props.theme.colors.graySubtitle};
    background-color: ${(props) => props.theme.colors.grayBgButton};
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

const Tbody = styled.tbody`
  &:before {
    content: '@';
    display: block;
    line-height: 6px;
    text-indent: -99999px;
  }
  tr {
    cursor: pointer;
  }
  tr:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.grayBgButton};
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 12px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 14px 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledStatusLabel = styled.div`
  color: ${(props) => props.theme.colors.orangeLightText};
  background-color: ${(props) => props.theme.colors.orangeLightBg};
  padding: 4px 15px;
  border-radius: 6px;
  line-height: 16px;
  width: fit-content;
  min-width: 122px;
  text-align: center;
  ${(propList: { status?: string }) =>
    propList.status?.toLowerCase() === 'прочитано' &&
    css`
      color: ${(props) => props.theme.colors.greenLightText};
      background-color: ${(props) => props.theme.colors.greenLightBg};
    `}
`;

const SearchInput = styled.input`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  color: ${(props) => props.theme.colors.graySubtitle};
  border: 1px solid ${(props) => props.theme.colors.grayBgButton};
  line-height: 22px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 45px 8px 16px;
  outline: none;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.greenBg};
  }
`;

const columns = [
  {
    field: 'number',
    label: 'Номер заявки',
    controls: false,
  },
  {
    field: 'date',
    label: 'Дата получения',
    controls: true,
  },
  {
    field: 'product',
    label: 'Продукт',
    controls: true,
  },
  {
    field: 'type',
    label: 'Тип',
    controls: true,
  },
  {
    field: 'status',
    label: 'Статус',
    controls: true,
  },
] as {
  field: ClaimField;
  label: string;
  controls: boolean;
}[];

type ClaimListItem = Claim & {
  playgroundId: number;
};

export const ClaimsPage = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<{
    field: ClaimField;
    dir: 'desc' | 'asc';
  }>({
    field: 'number',
    dir: 'desc',
  });
  const [claims, setClaims] = useState<ClaimListItem[]>([]);

  useEffect(() => {
    specprojectService.getAll().then(({ data }) => {
      setClaims(
        data.map((claim) => ({
          playgroundId: claim.playbookId,
          number: String(claim.elementId),
          date: getClaimDateFormat(claim.dateOfReciving),
          product: `${claim.product.name}`,
          type: claim.playbookType,
          status: claim.playbookStatus,
        }))
      );
    });
  }, []);

  const filteredAndSortedClaims = useCallback(() => {
    const filtered = claims.filter((el) => el.number.includes(search));
    const sorted = filtered.sort((a, b) => {
      if (filter.field === 'number') {
        if (+a[filter.field] > +b[filter.field])
          return filter.dir === 'desc' ? -1 : 1;
        if (+a[filter.field] < +b[filter.field])
          return filter.dir === 'desc' ? 1 : -1;
      }
      if (filter.field === 'date') {
        if (
          stringToTimestamp(a[filter.field]) <
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          stringToTimestamp(a[filter.field]) >
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? 1 : -1;
      }
      if (a[filter.field] < b[filter.field])
        return filter.dir === 'desc' ? -1 : 1;
      if (a[filter.field] > b[filter.field])
        return filter.dir === 'desc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [search, claims, filter]);

  const buttonClickHandler = (id: string) => {
    navigate(`${id}`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <StyledClaimsWrapper>
      <Warning right />
      <PageHeader title="Заявки" />
      <StyledSearchField>
        <SearchInput
          type="text"
          placeholder="Поиск по заявкам"
          value={search}
          onChange={handleSearchChange}
        />
        <SearchIcon />
      </StyledSearchField>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th key={uuidv4()}>
                <div>
                  {column.label}
                  {column.controls && (
                    <InputControls
                      handleStepUpClick={() =>
                        setFilter({ field: column.field, dir: 'desc' })
                      }
                      handleStepDownClick={() =>
                        setFilter({ field: column.field, dir: 'asc' })
                      }
                    />
                  )}
                </div>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {filteredAndSortedClaims().map((claim) => (
            <Tr onClick={() => buttonClickHandler(String(claim.playgroundId))}>
              <Td>{`№${claim.number}`}</Td>
              <Td>{claim.date}</Td>
              <Td>{claim.product}</Td>
              <Td>{claim.type}</Td>
              <Td>
                <StyledStatusLabel status={claim.status}>
                  {claim.status}
                </StyledStatusLabel>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <QuestionLabel />
    </StyledClaimsWrapper>
  );
};
