import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
// import { Route, Routes, BrowserRouter } from 'react-router-dom';
// import { LoginPage } from '../pages';
import { Sidebar } from '../components/Sidebar';
import { PrivateRoutes } from '../components/PrivateRoutes';
import { useCheckIsSuperAdmin } from './useSuperAdminCheck';

const StyledRouter = styled.div`
  display: flex;
  height: 100vh;
  margin-left: 76px;
`;

const useRoutes = () => {
  const isSuperAdmin = useCheckIsSuperAdmin();
  const isDevices = localStorage.getItem('isDevices') === 'true';

  return isSuperAdmin !== null ? (
    <BrowserRouter>
      <StyledRouter>
        <Sidebar />
        <PrivateRoutes isSuperAdmin={isSuperAdmin} isDevices={isDevices} />
      </StyledRouter>
    </BrowserRouter>
  ) : null;
  // (
  //   <BrowserRouter>
  //     <Routes>
  //       <Route path="*" element={<LoginPage />} />
  //     </Routes>
  //   </BrowserRouter>
  // );
};

export default useRoutes;
