import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmButton,
  DeleteButton,
  InputSelect,
  PageHeader,
  PageLeftHalf,
  PageWrapper,
} from '../components';
import { SelectData } from '../models';
import { seasonService } from '../services';

import { QuestionLabel } from '../components/common/QuestionLabel';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import { Warning } from '../components/common/Warning';

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

export const SeasonManagement = () => {
  const navigate = useNavigate();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const [mounted, setMounted] = useState(false);
  const [seasonsData, setSeasonsData] = useState<SelectData[]>([]);
  const [season, setSeason] = useState({
    id: '0',
    name: 'Выберите Сезон',
    startMechDate: '',
  });
  const [showMetodology, setShowMetodology] = useState(true);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const seasonsRes = (await seasonService.getAll()).data;

      const seasons = seasonsRes.map((el) => ({
        id: String(el.playbookId),
        name: el.name,
        startMechDate: el.startMechDate,
      }));
      setSeasonsData(seasons);
    }
    if (mounted) {
      fetchData();
    }
  }, [isSuperAdmin, mounted]);

  const handleHideMetodology = () =>
    setShowMetodology((prevState) => !prevState);

  const StartSeason = () =>
    new Promise<void>((resolve) => {
      seasonService.start(Number(season.id)).then(() => {
        resolve();
      });
    });

  const DeleteSeason = () =>
    new Promise<void>((resolve) => {
      seasonService.delete(Number(season.id)).then(() => {
        resolve();
      });
    });
  const StopSeason = () =>
    new Promise<void>((resolve) => {
      seasonService.stop(Number(season.id)).then(() => {
        resolve();
      });
    });

  return (
    <PageWrapper>
      <Warning right />
      <PageLeftHalf showMetodology={showMetodology}>
        <StyledPageLeftContent>
          <PageHeader
            onClick={() => navigate('/gamification')}
            title="Управление сезонами"
          />

          <InputSelect
            data={seasonsData}
            inputValue={season}
            setInputValue={setSeason}
            label="Сезон"
            mb={23}
          />
          {season.id !== '0' && isSuperAdmin && (
            <div>
              {season.startMechDate === null && (
                <ConfirmButton
                  mt={26}
                  modalBtnText="Хорошо"
                  disable={season.startMechDate !== null}
                  modalMessage="Сезон запущен"
                  text="Запустить сезон"
                  onClick={StartSeason}
                />
              )}

              {season.startMechDate !== null && (
                <ConfirmButton
                  mt={26}
                  modalBtnText="Хорошо"
                  disable={season.startMechDate === null}
                  modalMessage="Cезон остановлен"
                  text="Остановить сезон"
                  onClick={StopSeason}
                />
              )}

              <DeleteButton
                mt={26}
                modalBtnText="Хорошо"
                disable={season.startMechDate !== null}
                modalMessage="Cезон удален"
                text="Удалить сезон"
                onClick={DeleteSeason}
              />
            </div>
          )}
        </StyledPageLeftContent>
      </PageLeftHalf>

      <QuestionLabel onClick={handleHideMetodology} />
    </PageWrapper>
  );
};
