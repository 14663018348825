import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  ConfirmButton,
  InputSelect,
  PageHeader,
  PageWrapper,
} from '../components';
import {
  Achievement,
  allRanks,
  medalsMetrics,
  rankMetric,
  SeasonAwards,
  SeasonStatistic,
  SelectData,
  taskMetrics,
  UpdateTask,
} from '../models';
import { achievementService, seasonService, taskService } from '../services';

import { QuestionLabel } from '../components/common/QuestionLabel';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import { metricService } from '../services/metricService';
import { MetricsModal } from '../components/common/MetricsModal';

const StyledPageLeftContent = styled.div`
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .taskBlock {
    border-radius: 10px;
    margin: 20px;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.5);
  }
  .awardStat {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .value {
      font-size: 30px;
      text-align: right;
      font-weight: bold;
    }
  }
`;
const AwardCard = styled.div`
  padding: 5px;
  cursor: pointer;
  margin: 10px;
  width: 204px;
  min-height: 114px;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  .productStyle {
    font-weight: bold;
    margin-bottom: 5px;
  }
`;
export const MetricsPage = () => {
  const products = [
    {
      product: 'СберАгент',
    },
    {
      product: 'Wellness',
    },
    {
      product: 'Widget',
    },
    {
      product: 'СберДевайс',
    },
  ];
  const navigate = useNavigate();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const [tasks, setTasks] = useState<UpdateTask[]>([]);
  const [modalType, setModalType] = useState('');
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [mounted, setMounted] = useState(false);
  const [seasonAwards, setSeasonAwards] = useState<SeasonAwards[]>([]);
  const [allRanksData, setAllRanksData] = useState<allRanks[]>([]);
  const [tasksMetrics, setTaskMetrics] = useState<taskMetrics>();
  const [rankMetrics, setRankMetric] = useState<rankMetric>();
  const [medalsData, setMedalsMetics] = useState<medalsMetrics>();
  const [seasonsData, setSeasonsData] = useState<SelectData[]>([]);
  const [taskName, setTaskName] = useState<any>();
  const [actionId, setActionId] = useState<any>();
  const [showModal, setModal] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [awardStatictis, setAwardStatistic] = useState<SeasonStatistic>();
  const [season, setSeason] = useState({
    id: '0',
    name: 'Выберите Сезон',
    startMechDate: '',
  });
  const [showMetodology, setShowMetodology] = useState(true);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const seasonsRes = (await seasonService.getAll()).data;

      const seasons = seasonsRes?.map((el) => ({
        id: String(el.playbookId),
        name: el.name,
        startMechDate: el.startMechDate,
      }));
      setSeasonsData(seasons);
    }
    if (mounted) {
      fetchData();
    }
  }, [isSuperAdmin, mounted]);
  const handleToggleModal = () => {
    setModal((prev) => !prev);
  };
  const handleModalSuccess = () => {
    setModal(false);
  };

  const handleHideMetodology = () =>
    setShowMetodology((prevState) => !prevState);
  useEffect(() => {
    taskService.getAll(1, 9999).then(({ data }) => setTasks(data));
    achievementService.getAll(1, 9999).then(({ data }) => {
      setAchievements(data);
    });
    metricService.getRanksMetrics().then((response) => {
      setRankMetric(response.data);
    });
    metricService.getMedalsMetric().then((response) => {
      setMedalsMetics(response.data);
    });
    metricService.getAllRanks().then((response) => {
      setAllRanksData(response.data);
    });
    metricService.getTaskMetrics().then((response) => {
      setTaskMetrics(response.data);
    });
    metricService.getMetrics(season.id).then((response: any) => {
      setSeasonAwards(response.data);
    });

    metricService.getAwardStatistic().then((response) => {
      setAwardStatistic(response.data);
    });
  }, [season]);

  const taskList = tasks?.map((element) => (
    <AwardCard
      onClick={() => {
        setModalType('task');
        handleToggleModal();
        setTaskName(element.description);
        setTaskId(element.playbookId);
        setActionId(element.actionId);
      }}
    >
      <div
        style={{
          color: element.isActive ? 'green' : 'red',
        }}
        className="title"
      >
        {element.description}
      </div>
      <div>{element.product.name}</div>
      <div>ID:{element.playbookId}</div>
      <div>Количество повторений:{element.actionsCount}</div>
    </AwardCard>
  ));

  const rankElements = allRanksData?.map((element) => (
    <AwardCard
      onClick={() => {
        setModalType('rank');
        handleToggleModal();
        setTaskName(element.name);
        setTaskId(element.id);
        setActionId(element.id);
      }}
    >
      <div className="title">{element.name}</div>
      <div>ID:{element.id}</div>
      <div>{element.description}</div>
    </AwardCard>
  ));
  const taskElements = (
    <div className="awardStat">
      <div>
        <AwardCard>
          Общее количество заданиий в сезонах:{' '}
          <div className="value">{tasksMetrics?.countAllTasks} </div>
        </AwardCard>
        <AwardCard>
          Общее количество активных заданий:{' '}
          <div className="value">{tasksMetrics?.countAllActiveTasks} </div>
        </AwardCard>
      </div>
      <div>
        <AwardCard>
          Общее количество завершенных заданий:{' '}
          <div className="value">{tasksMetrics?.countAllCompleteTask} </div>
        </AwardCard>
        <AwardCard>
          Количество завершенных заданий в текущем сезоне:{' '}
          <div className="value">
            {tasksMetrics?.countAllCompletedTaskAtActiveSeason}{' '}
          </div>
        </AwardCard>
      </div>
    </div>
  );
  const medalsElements = (
    <div className="awardStat">
      <div>
        <AwardCard>
          Общее количество медалей:{' '}
          <div className="value">{medalsData?.countAllAchievements} </div>
        </AwardCard>
        <AwardCard>
          Общее количество полученных медалей:{' '}
          <div className="value">{medalsData?.countAllGottenAchievements} </div>
        </AwardCard>
      </div>
      <div>
        <AwardCard>
          Количество полученных медалей в текущем сезоне:{' '}
          <div className="value">
            {medalsData?.countCompletedAchievementsNumber}{' '}
          </div>
        </AwardCard>
        <AwardCard>
          Количество медалей полученных в качестве награды сезона:{' '}
          <div className="value">
            {medalsData?.countCompletedAchievementsNumberInCurrentSeason}{' '}
          </div>
        </AwardCard>
      </div>
    </div>
  );
  const medalsList = achievements?.map((element) => (
    <AwardCard
      onClick={() => {
        setModalType('medal');
        handleToggleModal();
        setTaskName(element.name);
        setTaskId(element.playbookId);
        setActionId(element.actionId);
      }}
    >
      <div className="title">{element.name}</div>
      <div>{element.product.name}</div>
      <div>ID:{element.playbookId}</div>
    </AwardCard>
  ));
  const seasonAwardsElemets = seasonAwards?.map((element) => (
    <AwardCard
      onClick={() => {
        // setCurrentLvl(element.level);
        // metricService.awardCompleteExport();
      }}
      key={element.id}
    >
      <div className="title">{element.description}</div>
      <div className="productStyle">
        {products[element.productId - 1].product}
      </div>
      <div>Уровень награды:{element.level} </div>
      <div>Открыли описание награды:{element.countOpenDescription}</div>
      <div>Достигли награды:{element.countAchieved}</div>
      <div>Забрали награду:{element.countTaken}</div>
      <div />
    </AwardCard>
  ));
  function fileDownload(response: any) {
    // console.log(response);
    const href = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'file.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
  function medalFileDownload(response: any) {
    //  console.log(response);

    console.log(response);
    const href = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'file.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
  return (
    <PageWrapper>
      <StyledPageLeftContent>
        <PageHeader onClick={() => navigate('/gamification')} title="Метрики" />
        {showModal && (
          <MetricsModal
            type={modalType}
            taskId={taskId}
            taskName={taskName}
            actionId={actionId}
            onClose={handleToggleModal}
            onSuccess={handleModalSuccess}
          />
        )}

        <div className="wrapper">
          <div>
            <div className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Общая статистика по заданиям
              </span>
              <div>{taskElements}</div>
            </div>
            <div className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Список заданий
              </span>
              <div className="awardStat">{taskList}</div>
            </div>
            <div className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Общая статистика по медалям
              </span>
              <div>{medalsElements}</div>
              <div style={{ padding: 10 }}>
                <ConfirmButton
                  disable={false}
                  onClick={() => {
                    metricService.achievementExport().then((response) => {
                      const blob = new Blob([response.data], {
                        type: 'text/xml',
                      });
                      medalFileDownload(blob);
                    });
                  }}
                  text="Статистика по медалям"
                />
              </div>
            </div>
            <div className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Список медалей
              </span>
              <div className="awardStat">{medalsList}</div>
            </div>
          </div>
          <div>
            <div className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Общая статистика по сезонам
              </span>
              <div className="awardStat">
                <div>
                  <AwardCard>
                    Общее количество наград в сезонах:{' '}
                    <div className="value">{awardStatictis?.totalAwards} </div>
                  </AwardCard>
                  <AwardCard>
                    Общее количество полученных наград:{' '}
                    <div className="value">
                      {' '}
                      {awardStatictis?.totalTakenAwards}
                    </div>
                  </AwardCard>
                </div>
                <div>
                  <AwardCard>
                    Полученное количество наград в этом сезоне:
                    <div className="value">
                      {awardStatictis?.totalTakenAwardsCurrentSeason}{' '}
                    </div>
                  </AwardCard>
                  <AwardCard>
                    Количество пользователей получивших финальную награду:{' '}
                    <div className="value">
                      {awardStatictis?.totalFinalAwards}
                    </div>
                  </AwardCard>
                </div>
              </div>
            </div>
            <div style={{ padding: 10 }} className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Статистика по сезону
              </span>
              <div style={{ marginBottom: 5, marginTop: 5 }}>
                <ConfirmButton
                  disable={false}
                  onClick={() => {
                    metricService.awardCompleteExport().then((response) => {
                      fileDownload(response);
                    });
                  }}
                  text="Пользователи, достигшие награды"
                />
              </div>
              <ConfirmButton
                disable={false}
                onClick={() => {
                  metricService.awardTakenExport().then((response) => {
                    fileDownload(response.data);
                  });
                }}
                text="Пользователи, получившие награду"
              />
              <div style={{ width: 300 }}>
                <InputSelect
                  data={seasonsData}
                  inputValue={season}
                  setInputValue={setSeason}
                  label="Сезон"
                  mb={23}
                />
              </div>

              <div>{seasonAwardsElemets}</div>
            </div>
            <div className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Общая статистика по рангам
              </span>
              <div className="awardStat">
                <div>
                  <AwardCard>
                    Всего поменяли звание:{' '}
                    <div className="value">
                      {rankMetrics?.countUserChangedRank}{' '}
                    </div>
                  </AwardCard>
                  <AwardCard>
                    Открыли список званий:{' '}
                    <div className="value">
                      {' '}
                      {rankMetrics?.countUserOpenRankList}
                    </div>
                  </AwardCard>
                </div>
                <div>
                  <AwardCard>
                    Общее количество открытых званий:
                    <div className="value">
                      {rankMetrics?.openedRanksCount}{' '}
                    </div>
                  </AwardCard>
                  <AwardCard>
                    Всего званий:{' '}
                    <div className="value">{rankMetrics?.rankCount}</div>
                  </AwardCard>
                </div>
              </div>
            </div>
            <div className="taskBlock">
              <span style={{ padding: 10 }} className="title">
                {' '}
                Список званий
              </span>
              <div className="awardStat">{rankElements}</div>
            </div>
          </div>
        </div>
      </StyledPageLeftContent>

      <QuestionLabel onClick={handleHideMetodology} />
    </PageWrapper>
  );
};
