import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  showMetodology?: boolean;
};

export const StyledLeftHalf = styled.div`
  ${(props: Props) =>
    props.showMetodology &&
    css`
      max-width: 50%;
    `};
  width: 100%;
  padding-right: 126px;
`;

export const PageLeftHalf = (props: {
  children: ReactNode;
  showMetodology?: boolean;
}) => {
  const { children } = props;
  return <StyledLeftHalf {...props}>{children}</StyledLeftHalf>;
};
