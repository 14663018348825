import { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import FontStyles from '../global/globalFonts';
import PagesContainer from './PagesContainer';

export const App = () => (
  <ThemeProvider theme={theme}>
    <FontStyles />
    <PagesContainer />
  </ThemeProvider>
);
