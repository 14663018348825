import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FileSaver from 'file-saver';
import avatar from '../assets/images/avatar.png';
import { PageHeader, Typography } from '../components';
import { theme } from '../theme';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { specprojectService } from '../services';
import {
  ContentWrapper as FileLabelWrapper,
  FileSizeLabel,
} from '../components/common/FileLabel';
import { getClaimDateFormat, getFileSize } from '../utils';

const StyledClaimPageWrapper = styled.div`
  margin: 24px 120px;
  max-width: 436px;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayBorderButton};
  margin-top: -16px;
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  & > div {
    margin-right: 6px;
    &:nth-child(2) {
      margin-right: 24px;
    }
  }
`;

const AuthorInfoWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const AuthorInfo = styled.div`
  & > div {
    margin-bottom: 4px;
    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.colors.graySubtitle};
      width: fit-content;
    }
  }
`;

const ClaimInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  & > div:first-child {
    margin-bottom: 8px;
  }
  & > div:nth-child(3) {
    cursor: pointer;
  }
`;

const Avatar = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 14px;
`;

export const ClaimPage = () => {
  const { graySubtitle, blackLabel } = theme.colors;
  const navigate = useNavigate();
  const location = useLocation();

  const [number, setNumber] = useState('');
  const [date, setDate] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [product, setProduct] = useState('');
  const [description, setDescription] = useState('');
  const [attachment, setAttachment] = useState<{
    name: string;
    size: number;
    id: number;
    type: string;
  }>();
  const [, setShowMetodology] = useState(true);

  useEffect(() => {
    const id = location.pathname.split('/').slice(-1)[0];
    specprojectService.getByPlaybookId(+id).then(({ data }) => {
      setDate(getClaimDateFormat(data.dateOfReciving));
      setFullName(data.fullName);
      setEmail(data.email);
      setProduct(data.product.name);
      setDescription(data.description);
      setNumber(String(data.elementId));
      if (data.attachment)
        setAttachment({
          name: data.attachment.fileName,
          size: data.attachment.size,
          id: data.attachment.id,
          type: data.attachment.fileType,
        });
    });
  }, [location.pathname]);

  const handleHideMetodology = () =>
    setShowMetodology((prevState) => !prevState);

  const getSize = () => `${getFileSize(attachment?.size)} mb.`;

  const handleLoadFile = () => {
    if (attachment)
      specprojectService.getAttachment(attachment.id).then((data) => {
        const blob = new Blob([data.data], { type: attachment.type });
        const fileName = attachment.name;
        FileSaver.saveAs(blob, fileName);
      });
  };

  return (
    <StyledClaimPageWrapper>
      <PageHeader
        onClick={() => navigate('/claims')}
        title="Заявка на спецпроект"
      />
      <Title>
        <Typography color={graySubtitle} lh={24}>
          номер заявки
        </Typography>
        <Typography color={blackLabel} lh={24}>
          #{number}
        </Typography>
        <Typography color={graySubtitle} lh={24}>
          дата получения
        </Typography>
        <Typography color={blackLabel} lh={24}>
          {date}
        </Typography>
      </Title>
      <AuthorInfoWrapper>
        <Avatar src={avatar as string} alt="avatar" />
        <AuthorInfo>
          <Typography color="#000" lh={20} fz={16} fw={600} ff="SB Sans Text">
            {fullName}
          </Typography>
          <Typography color={graySubtitle} lh={20} ff="SB Sans Text">
            {email}
          </Typography>
        </AuthorInfo>
      </AuthorInfoWrapper>
      <ClaimInfo>
        <Typography color="#000" lh={30} fz={20} fw={600} ff="SB Sans Text">
          {product}
        </Typography>
        <Typography color={blackLabel} lh={20} fz={16}>
          {description}
        </Typography>
        {attachment && (
          <FileLabelWrapper onClick={handleLoadFile}>
            <div>{attachment?.name}</div>
            <FileSizeLabel>{getSize()}</FileSizeLabel>
          </FileLabelWrapper>
        )}
      </ClaimInfo>
      <QuestionLabel onClick={handleHideMetodology} />
    </StyledClaimPageWrapper>
  );
};
