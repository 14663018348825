import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputControls } from './InputControls';
import { addDays } from '../../../utils';

type Props = {
  label: string;
  date: { value: Date; touched: boolean };
  // eslint-disable-next-line no-unused-vars
  setDate: (date: { value: Date; touched: boolean }) => void;
  min?: Date;
  max?: Date;
  mb?: number;
};

const StyledInputDate = styled.div`
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.blackLabel};
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  position: relative;
  input {
    border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
    cursor: pointer;
    height: 41px;
    font-size: 16px;
    outline: none;
    resize: none;
    width: 100%;
    padding: 10px 16px;
    border-radius: 6px;
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.greenBg};
    }
    &.untouched {
      color: ${(props) => props.theme.colors.graySubtitle};
    }
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
`;

export const InputDate = (props: Props) => {
  const { label, date, setDate, min, max } = props;

  const handleStepUpClick = () => {
    const result = addDays(date.value, 1);

    if (!max || (max && result <= max)) {
      setDate({ value: result, touched: true });
    }
  };

  const handleStepDownClick = () => {
    const result = addDays(date.value, -1);

    if (!min || (min && result >= min)) {
      setDate({ value: result, touched: true });
    }
  };

  const handleChange = (value: Date) =>
    value && setDate({ value, touched: true });

  return (
    <StyledInputDate {...props}>
      <Label>{label}</Label>
      <InputWrapper>
        <DatePicker
          className={[!date.touched && 'untouched'].join(' ')}
          selected={date.value}
          onChange={handleChange}
          dateFormat="dd.MM.yyyy"
          minDate={min}
          maxDate={max}
        />
        <InputControls
          right="12px"
          top="20.5px"
          handleStepDownClick={handleStepDownClick}
          handleStepUpClick={handleStepUpClick}
        />
      </InputWrapper>
    </StyledInputDate>
  );
};
