import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowIcon } from '../../../assets/icons';
import { theme } from '../../../theme';

type Props = {
  text: string;
  onClick?: () => void;
  color?: string;
  link?: string;
  label?: string;
  noBorder?: boolean;
  disable?: boolean;
};

const StyledButton = styled.button<Props>`
  background-color: ${(props: Props) => props.color ?? '#fff'};
  border: 1px solid
    ${(props) =>
      props.noBorder
        ? props.color ?? '#fff'
        : props.theme.colors.grayBorderButton};
  width: 100%;
  max-width: 384px;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 8px;
  text-align: left;
  position: relative;
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
  & > svg {
    transform: rotateY(180deg) translateY(-50%);
    position: absolute;
    right: 24px;
    top: 50%;
  }
`;

const Label = styled.span`
  color: ${(props) => props.theme.colors.greenTitle};
  display: inline-block;
  font-family: 'SB Sans Display';
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  margin-right: 16px;
`;

const Text = styled.div<{ disable?: boolean }>`
  color: ${(props) =>
    props.disable
      ? props.theme.colors.graySubtitle
      : props.theme.colors.blackLabel};
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
`;

export const Button = (props: Props) => {
  const { label, text, link, onClick, disable } = props;

  const { graySubtitle } = theme.colors;

  const navigate = useNavigate();

  const buttonClickHandler = () => {
    if (onClick) {
      onClick();
    }
    if (link) {
      navigate(link);
    }
  };

  return (
    <StyledButton
      {...props}
      onClick={disable ? () => null : buttonClickHandler}
    >
      {label && <Label>{label}</Label>}
      <Text disable={disable}>{text}</Text>
      <ArrowIcon fill={disable ? graySubtitle : 'black'} />
    </StyledButton>
  );
};
