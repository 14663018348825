import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  DocsIcon,
  EditIcon,
  FingerIcon,
  GamificationIcon,
  PersonIcon,
  SmartphoneIcon,
} from '../assets/icons';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';

const StyledSidebar = styled.div`
  background-color: ${(props) => props.theme.colors.blackSidebar};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: fixed;
  width: 76px;
  margin-left: -76px;
  padding: 32px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  margin-bottom: 11px;
  &:nth-child(2) {
    margin-top: 148px;
  }
  &.active {
    background: ${(props) => props.theme.colors.blackLabel};
  }
  & > a {
    display: flex;
    & > svg {
      cursor: pointer;
    }
  }
`;

export const Sidebar = () => {
  const pathname = useLocation().pathname.slice(1);
  const isSuperAdmin = useCheckIsSuperAdmin();

  const getIconFill = (link: string) =>
    pathname.includes(link) ? '#fff' : undefined;

  const iconsData = [
    {
      link: 'gamification',
      icon: <EditIcon fill={getIconFill('gamification')} />,
    },
    {
      link: 'claims',
      icon: <DocsIcon fill={getIconFill('claims')} />,
    },
    {
      link: 'admins',
      icon: <SmartphoneIcon fill={getIconFill('admins')} />,
    },
    {
      link: 'employees',
      icon: <PersonIcon fill={getIconFill('employees')} />,
    },
    {
      link: 'metrics',
      icon: <FingerIcon fill={getIconFill('metrics')} />,
    },
  ];

  return (
    <StyledSidebar>
      <Link to="/main">
        <GamificationIcon />
      </Link>
      {isSuperAdmin &&
        iconsData.map((el) => (
          <IconWrapper
            key={uuidv4()}
            className={[pathname.includes(el.link) && 'active'].join(' ')}
          >
            <Link to={el.link}>{el.icon}</Link>
          </IconWrapper>
        ))}
    </StyledSidebar>
  );
};
