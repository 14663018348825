import { ReactNode } from 'react';
import styled from 'styled-components';
import { Warning } from '../Warning';

export const StyledRightHalf = styled.div`
  min-height: 100%;
  min-width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 94px 0 0 24px;
  position: relative;
`;

const DividerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const Divider = styled.div`
  top: -24px;
  position: fixed;
  width: 1px;
  height: calc(100% + 24px);
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: lightgray; stroke-width: 4; stroke-dasharray: 7 12'/></svg>");
`;

const Title = styled.div`
  margin-bottom: 10px;
  font-family: 'SB Sans Text';
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.previewSubtitle};
  font-family: 'SB Sans Text';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 400px;
  margin-bottom: 24px;
`;

export const PageRightHalf = ({
  children,
  hiddenTitle,
}: {
  children: ReactNode;
  hiddenTitle?: boolean;
}) => (
  <StyledRightHalf>
    <Warning />
    <DividerWrapper>
      <Divider />
    </DividerWrapper>
    {!hiddenTitle && (
      <>
        <Title>Предварительный просмотр</Title>
        <Description>
          Меняйте пункты в заявке, вместе с ними будет меняться и карточка
          заданий
        </Description>
      </>
    )}
    {children}
  </StyledRightHalf>
);
