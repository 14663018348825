import { theme } from '../../theme';

export const ArrowIcon = ({ fill }: { fill?: string }) => {
  const { grayDark } = theme.colors;
  return (
    <svg
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 11.25L1 6L6.25 0.75"
        stroke={fill ?? grayDark}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
