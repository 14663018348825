import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmButton,
  PageHeader,
  PageLeftHalf,
  PageWrapper,
  InputText,
  InputSelect,
} from '../components';
import { SelectData } from '../models';
import { mapService, productService } from '../services';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

const trophyInit = {
  id: '0',
  name: 'Выберите трофей',
};

export const MapCreatePage = () => {
  const navigate = useNavigate();
  const isSuperAdmin = useCheckIsSuperAdmin();

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');
  const [elementCount, setElementCount] = useState('');
  const [trophy, setTrophy] = useState<SelectData>(trophyInit);
  const [awardsData, setAwardsData] = useState<
    { id: string; name: string; imageUrl: string }[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      mapService.getAllAwards().then(({ data }) =>
        setAwardsData(
          data.map((t) => ({
            id: String(t.id),
            name: t.name,
            imageUrl: `https://static.playground.sysdyn.ru/${t.imageFileKey}`,
          }))
        )
      );
      productService.getAll().then(({ data }) => {
        const products = data.map((el) => ({
          id: String(el.id),
          name: el.name,
          disabled: el.id !== 4,
        }));
        setProductsData(products);
        if (!isSuperAdmin)
          setProduct(products[products.findIndex((el) => el.id === '4')]);
      });
    }
    if (isSuperAdmin !== null) fetchData();
  }, [isSuperAdmin]);

  const handleMapCreate = () =>
    new Promise<void>((resolve) => {
      setLoading(true);
      const map = {
        description,
        duration: Number(duration),
        mapAwardId: Number(trophy.id),
        name,
        elementCount: Number(elementCount),
        productId: Number(product.id),
      };

      mapService.createMap(map).then((response) => {
        if (response.status === 200) {
          resolve();
        }
      });

      resolve();
      setLoading(false);
    });

  const handleCreateBtnClick = () => handleMapCreate();

  const isValid = () =>
    !loading &&
    product.id !== '0' &&
    name.length &&
    description.length &&
    duration.length &&
    elementCount !== '' &&
    trophy.id !== '0';

  return (
    <PageWrapper>
      <PageLeftHalf showMetodology={false}>
        <StyledPageLeftContent>
          <PageHeader
            onClick={() => navigate('/gamification')}
            title="Создание карты"
          />

          {isSuperAdmin && (
            <InputSelect
              data={productsData}
              inputValue={product}
              setInputValue={setProduct}
              label="Продукт"
              mb={23}
            />
          )}
          <InputText
            inputValue={name}
            setInputValue={setName}
            label="Название"
            placeholder="Введите название карты"
            mb={23}
          />
          <InputText
            inputValue={description}
            setInputValue={setDescription}
            label="Описание"
            placeholder="Введите описание карты"
            mb={23}
          />
          <InputText
            inputValue={duration}
            setInputValue={setDuration}
            label="Срок жизни"
            placeholder="Введите срок жизни"
            min={1}
            max={1000}
            mb={23}
            hint="Считается в днях"
          />
          <InputText
            inputValue={elementCount}
            setInputValue={setElementCount}
            label="Количество заданий"
            placeholder="Введите кол-во заданий"
            min={2}
            max={7}
            mb={23}
            hint="От 2 до 7"
          />
          <InputSelect
            data={awardsData}
            inputValue={trophy}
            setInputValue={(v) => setTrophy({ id: v.id, name: v.name })}
            label="Трофей"
            mb={23}
          />
          <ConfirmButton
            onClick={handleCreateBtnClick}
            text="Создать карту"
            modalMessage="Карта создана"
            modalBtnText="Хорошо"
            disable={!isValid()}
            mt={23}
          />
        </StyledPageLeftContent>
      </PageLeftHalf>
    </PageWrapper>
  );
};
