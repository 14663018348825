import { useEffect, useState } from 'react';
import useRoutes from '../hooks/useRoutes';
import MainLoginPage from '../pages/MainLoginPage';
import { cookies } from '../utils';

const PagesContainer = () => {
  const routes = useRoutes();

  const authCookie = cookies.get('authValue');

  const [isLoggedIn, setIsLoggedIn] = useState(
    !!JSON.parse(localStorage.getItem('PG_is_logged_in')!) && !!authCookie
  );

  useEffect(() => {
    window.localStorage.setItem(
      'PG_is_logged_in',
      JSON.stringify(isLoggedIn && !!authCookie)
    );
  }, [isLoggedIn, authCookie]);

  return (
    <>
      {isLoggedIn && routes}
      {!isLoggedIn && <MainLoginPage onLogin={() => setIsLoggedIn(true)} />}
    </>
  );
};

export default PagesContainer;
