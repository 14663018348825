import axios, { AxiosError, AxiosInstance } from 'axios';
import { cookies } from '../utils';

export const API_URL =
  process.env.REACT_APP_API_URL || 'https://admin.playground.sysdyn.ru/api/v1/';

const api: AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

api.interceptors.request.use((config) => {
  const auth = cookies.get<{ type: string; token: string }>('authValue');
  if (
    config.url?.includes('playbooks/specprojects/attachments/attachmentId=')
  ) {
    // eslint-disable-next-line no-param-reassign
    config.responseType = 'blob';
  }
  if (auth && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = auth.token;
  }
  return config;
});

api.interceptors.response.use(
  (config) => config,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      cookies.remove('authValue');
      window.location.reload();
    }
    return error;
  }
);

export default api;
