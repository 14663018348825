import styled from 'styled-components';
import { theme } from '../../theme';
import { Indicator } from './Indicator';
import { Typography } from './Typography';

type Props = { currentXP: string; levelXP: string; mb: number };

const StyledSberXP = styled.div`
  margin-bottom: ${({ mb }: { mb: number | undefined }) =>
    mb ? `${mb}px;` : 'unset'};
  border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
  border-radius: 6px;
  padding: 10px 16px;
`;

export const SberXP = (props: Props) => {
  const { currentXP, levelXP } = props;
  const { graySubtitle } = theme.colors;

  return (
    <StyledSberXP {...props}>
      <Typography fz={16} lh={20} mb={8}>
        Ваше количество SberXP в этом месяце
      </Typography>
      <Indicator currentXP={currentXP} levelXP={levelXP} />
      <Typography fz={14} lh={20} color={graySubtitle}>
        Осталось 7200 SberXP
      </Typography>
    </StyledSberXP>
  );
};
