import styled from 'styled-components';
import { ReactNode } from 'react';

const AchievementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const AchievementImage = styled.img`
  width: 103px;
  height: 103px;
  margin-bottom: 15px;
  align-self: center;
`;

const Title = styled.div`
  font-family: 'SB Sans Text';
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
  color: #2b2b2b;
  width: 153px;
  word-wrap: break-word;
`;

export const Achievement = ({
  children,

  achievementImg,
}: {
  children: ReactNode;

  achievementImg?: string;
}) => (
  <AchievementWrapper>
    {achievementImg && (
      <AchievementImage src={achievementImg} alt="achievement" />
    )}
    <Title>{children}</Title>
  </AchievementWrapper>
);
