import api from '../http';
import { cookies } from '../utils';

export const authService = {
  login: (credentials: string, type: string) =>
    api
      .post('login', null, {
        headers: { Authorization: `Basic:${credentials}` },
      })
      .then(({ data }: { data: { type: string; token: string } }) => {
        cookies.set('authValue', { type, token: `${data.type} ${data.token}` });
      }),
};
