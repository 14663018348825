import { useEffect, useState } from 'react';
import { cookies } from '../utils';

export const useCheckIsSuperAdmin = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | null>(null);

  const cookiesCallback = ({ name }: { name: string }) => {
    if (name === 'authValue') {
      window.location.reload();
    }
  };

  useEffect(() => {
    cookies.addChangeListener(cookiesCallback);

    const type = cookies.get<{ token: string; type: string }>(
      'authValue'
    )?.type;
    if (type) setIsSuperAdmin(type === 'super');

    return () => cookies.removeChangeListener(cookiesCallback);
  }, []);

  return isSuperAdmin;
};
