import styled from 'styled-components';

import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';

type Props = {
  questionImg: string;
  title: string;
  xp: number;
  stepsDone: string;
  steps: string;
  bg: string;
  duration: number;
  description?: string;
  mb?: number;
  product?: string;
};

const StyledTask = styled.div`
  background: ${(props) => props.theme.colors.whiteBg};
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
  width: 100%;
  padding: 32px 16px;
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`;

const XpBlock = styled.div`
  position: relative;
  min-width: 40px;
  height: 40px;
  align-self: center;
`;

const XpBlockBackGround = styled.div<{ xp: number }>`
  width: 100%;
  height: 100%;
  background-color: ${({ xp }) =>
    // eslint-disable-next-line no-nested-ternary
    xp <= 50 ? '#8E8E96' : xp <= 200 ? '#3935ff' : '#9A57F2'};
  border-radius: 8px;
  transition: transform 0.2s ease;
`;

const XpBlockInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

const XpBlockNumber = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 12px;
`;

const XpBlockXpSign = styled.span`
  display: block;
  font-size: 9px;
  font-weight: 600;
  text-align: center;
`;

const TextBlock = styled.div`
  cursor: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 14px;
`;

const Title = styled.span`
  cursor: inherit;
  font-weight: 400;
  font-size: 20px;
  color: #212121;
  max-width: 220px;
  word-break: break-all;
`;

const Description = styled.span`
  display: flex;
  cursor: inherit;
  font-weight: 400;
  font-size: 14px;
  color: inherit;
`;

const ArrowBlock = styled.div`
  color: '#212121';
  transition: all 0.3s ease;
  opacity: 0.4;
  align-self: center;
  margin-right: 8px;
`;

const LevelBlock = styled.div`
  margin: 7px 0 7px 0;
  height: 8px;
  position: relative;
  background-color: #ebebeb;
  border: 0px solid black;
  border-radius: 4px;
  width: 100px;
  margin-right: 12px;
`;

const LevelProgress = styled.div<{ steps: string; stepsDone: string }>`
  position: absolute;
  background: #90d128;
  height: 8px;
  width: ${({ steps, stepsDone }) => (+stepsDone / +steps) * 100}%;
  border: 0px solid black;
  border-radius: 4px;
`;

const Text = styled.div`
  transition: opacity 0.3s ease;
  opacity: 0.4;
  margin-right: 12px;
`;

const LeftContainer = styled.div`
  display: flex;
`;

export const Task = (props: Props) => {
  const { steps, stepsDone, xp, description, product } = props;
  const progressText = `${stepsDone} из ${steps}`;

  return (
    <StyledTask {...props}>
      <LeftContainer>
        <XpBlock>
          <XpBlockBackGround xp={xp} />
          <XpBlockInner>
            <XpBlockNumber>{xp}</XpBlockNumber>
            <XpBlockXpSign>xp</XpBlockXpSign>
          </XpBlockInner>
        </XpBlock>
        <TextBlock>
          <Title>{description || 'Пример названия'}</Title>
          <Description>
            <LevelBlock>
              <LevelProgress {...{ steps, stepsDone }} />
            </LevelBlock>
            <Text>{progressText}</Text>
            <Text>{product === 'Выберите продукт' ? 'Пример' : product}</Text>
          </Description>
        </TextBlock>
      </LeftContainer>
      <ArrowBlock>
        <ArrowRightIcon />
      </ArrowBlock>
    </StyledTask>
  );
};
