export const GamificationIcon = ({ fill = '#fff' }: { fill?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2075 5.02905C21.3112 3.53529 19.7178 2.53943 17.9253 2.53943H5.97511C4.18256 2.53943 2.58923 3.53529 1.69294 5.02905C0.597516 7.02077 0 9.21166 0 11.5021C0 16.9793 2.19089 21.4606 4.97925 21.4606C6.47302 21.4606 7.66805 20.0664 8.5643 17.1784C8.66386 16.7801 9.0622 16.4814 9.56016 16.4814H14.4398C14.8382 16.4814 15.2365 16.7801 15.4357 17.1784C16.332 20.0664 17.527 21.4606 19.0208 21.4606C21.8091 21.4606 24 16.9793 24 11.5021C23.9004 9.21166 23.3029 7.02077 22.2075 5.02905ZM18.9211 19.4689C18.6224 19.4689 17.9253 18.6722 17.2282 16.5809C16.8299 15.2863 15.6348 14.4896 14.3402 14.4896H9.56016C8.26556 14.4896 7.07053 15.3859 6.67219 16.5809C5.97511 18.6722 5.27798 19.4689 4.97925 19.4689C3.98339 19.4689 1.99172 16.3818 1.99172 11.5021C1.99172 9.61 2.48962 7.7179 3.38592 6.02496C3.98344 5.12871 4.9793 4.53119 5.97516 4.53119H17.9254C18.9212 4.53119 19.9171 5.12871 20.5146 6.02496C21.4109 7.7179 21.9088 9.61 21.9088 11.5021C21.9087 16.3818 19.917 19.4689 18.9211 19.4689Z"
      fill={fill}
    />
    <path
      d="M8.99992 8.99997H7.99994V7.99998C7.99994 7.39998 7.59995 7 6.99995 7C6.39995 7 5.99997 7.39998 5.99997 7.99998V8.99997H4.99998C4.39998 8.99997 4 9.39995 4 9.99995C4 10.6 4.39998 10.9999 4.99998 10.9999H5.99992V12C5.99992 12.6 6.39991 13 6.99991 13C7.59991 13 7.99989 12.6 7.99989 12V11H8.99992C9.59992 11 9.99991 10.6 9.99991 10C9.99991 9.39995 9.59992 8.99997 8.99992 8.99997Z"
      fill={fill}
    />
    <path
      d="M18.5 10C19.3284 10 20 9.32843 20 8.5C20 7.67157 19.3284 7 18.5 7C17.6716 7 17 7.67157 17 8.5C17 9.32843 17.6716 10 18.5 10Z"
      fill={fill}
    />
    <path
      d="M15.5 13C16.3284 13 17 12.3284 17 11.5C17 10.6716 16.3284 10 15.5 10C14.6716 10 14 10.6716 14 11.5C14 12.3284 14.6716 13 15.5 13Z"
      fill={fill}
    />
  </svg>
);
