import styled from 'styled-components';
import { CrossIcon } from '../../assets/icons';
import { getFileSize } from '../../utils';

const StyledFileLabel = styled.div`
  display: table;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  padding-top: 2px;
  margin-left: 9px;
`;

export const FileSizeLabel = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  margin-left: 3px;
  margin-right: auto;
`;

export const ContentWrapper = styled.div`
  background: ${(props) => props.theme.colors.grayBgButton};
  color: ${(props) => props.theme.colors.blackLabel};
  display: flex;
  align-items: center;
  padding: 3px 10px;
  height: 26px;
  width: max-content;
  border-radius: 17px;
  margin-top: 16px;
  font-family: 'SB Sans Interface';
  font-size: 12px;
  line-height: 20px;
  & > div:first-child {
    max-width: 265px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const FileLabel = ({
  file,
  onRemove,
}: {
  file: File;
  onRemove: () => void;
}) => {
  const getFileName = () => {
    const withoutExtension = file.name.slice(0, file.name.lastIndexOf('.'));
    return withoutExtension;
  };

  const getSize = () => `${getFileSize(file.size)} mb.`;

  return (
    <StyledFileLabel>
      <ContentWrapper>
        <div>{getFileName()}</div>
        <FileSizeLabel>{getSize()}</FileSizeLabel>
        <IconWrapper>
          <CrossIcon onClick={onRemove} />
        </IconWrapper>
      </ContentWrapper>
    </StyledFileLabel>
  );
};
