import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../assets/icons';
import { InputSelect, PageHeader } from '../components';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { achievementService, taskService } from '../services';
import { Achievement, SelectData, UpdateTask } from '../models';
import { Warning } from '../components/common/Warning';
import { getClaimDateFormat } from '../utils';

const StyledClaimsWrapper = styled.div`
  margin: 10px 32px;
  width: 100%;
  .input-select {
    max-width: 360px;
  }
`;

const StyledSearchField = styled.div`
  position: relative;
  max-width: 360px;
  margin-bottom: 16px;
  & > svg {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  border-radius: 8px;
  tr {
    color: ${(props) => props.theme.colors.graySubtitle};
    background-color: ${(props) => props.theme.colors.grayBgButton};
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
      min-width: 180px;
    }
  }
`;

const Tbody = styled.tbody`
  &:before {
    content: '@';
    display: block;
    line-height: 6px;
    text-indent: -99999px;
  }
  tr:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.grayBgButton};
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 12px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 14px 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const SearchInput = styled.input`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  color: ${(props) => props.theme.colors.graySubtitle};
  border: 1px solid ${(props) => props.theme.colors.grayBgButton};
  line-height: 22px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 45px 8px 16px;
  outline: none;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.greenBg};
  }
`;

const taskColumns = [
  {
    field: 'playgroundId',
    label: 'Номер задания',
    controls: false,
  },
  {
    field: 'actionCount',
    label: 'Кол-во действий',
    controls: false,
  },
  {
    field: 'actionId',
    label: 'Действие',
    controls: false,
  },
  {
    field: 'description',
    label: 'Описание',
    controls: false,
  },
  {
    field: 'duration',
    label: 'Продолжительность',
    controls: false,
  },
  {
    field: 'product',
    label: 'Продукт',
    controls: false,
  },
  {
    field: 'experienceValue',
    label: 'Кол-во опыта',
    controls: false,
  },
];

const achievementColumns = [
  {
    field: 'playgroundId',
    label: 'Номер медали',
    controls: false,
  },
  {
    field: 'actionCount',
    label: 'Кол-во действий',
    controls: false,
  },
  {
    field: 'name',
    label: 'Название',
    controls: false,
  },
  {
    field: 'startDate',
    label: 'Дата старта',
    controls: false,
  },
  {
    field: 'product',
    label: 'Продукт',
    controls: false,
  },
  {
    field: 'imageUrl',
    label: 'Изображение',
    controls: true,
  },
];

export const TaskAndMedalsPage = () => {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [tasksData] = useState<SelectData[]>([
    {
      id: '1',
      name: 'Задания',
    },
    {
      id: '2',
      name: 'Медали',
    },
  ]);
  const [tasks, setTasks] = useState<UpdateTask[]>([]);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [dataType, setDataType] = useState({
    id: '1',
    name: 'Задания',
  });

  const isTaskTypeSelected = useCallback(() => dataType.id === '1', [dataType]);

  useEffect(() => {
    if (dataType.id === '0') return;
    if (isTaskTypeSelected())
      taskService.getAll(1, 9999).then(({ data }) => setTasks(data));
    achievementService.getAll(1, 9999).then(({ data }) => {
      setAchievements(data);
    });
  }, [dataType, isTaskTypeSelected]);

  const filteredData = useCallback(
    (data: Achievement[] | UpdateTask[]) =>
      data
        ? // @ts-ignore
          data.filter((el) => String(el.playbookId).includes(search))
        : [],
    [search]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  function getColumns() {
    if (isTaskTypeSelected()) return taskColumns;
    return achievementColumns;
  }

  return (
    <StyledClaimsWrapper>
      <Warning right />

      <PageHeader
        onClick={() => navigate('/gamification')}
        title="Задания и медали"
      />
      <StyledSearchField>
        <SearchInput
          type="text"
          placeholder="Поиск по id"
          value={search}
          onChange={handleSearchChange}
        />
        <SearchIcon />
      </StyledSearchField>
      <InputSelect
        className="input-select"
        data={tasksData}
        inputValue={dataType}
        setInputValue={setDataType}
        mb={23}
      />
      <Table>
        <Thead>
          <Tr>
            {getColumns().map((column) => (
              <Th key={uuidv4()}>
                <div>{column.label}</div>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isTaskTypeSelected()
            ? filteredData(tasks).map((task: UpdateTask) => (
                <Tr key={task.playbookId}>
                  <Td>{`№${task.playbookId}`}</Td>
                  <Td>{task.actionsCount}</Td>
                  <Td>{task.actionId}</Td>
                  <Td>{task.description}</Td>
                  <Td>{task.duration}</Td>
                  <Td>{task.product.name}</Td>
                  <Td>{task.experienceValue}</Td>
                </Tr>
              ))
            : filteredData(achievements).map((achievement: Achievement) => (
                <Tr key={achievement.playbookId}>
                  <Td>{`№${achievement.playbookId}`}</Td>
                  <Td>{achievement.actionsCount}</Td>
                  <Td>{achievement.name}</Td>
                  <Td>{getClaimDateFormat(achievement.startDate)}</Td>
                  <Td>{achievement.product.name}</Td>
                  <Td>
                    <img
                      width={50}
                      height={50}
                      src={achievement.imageUrl}
                      alt="img url"
                    />
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
      <QuestionLabel />
    </StyledClaimsWrapper>
  );
};
