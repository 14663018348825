import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../../theme';
import { Typography } from '../Typography';

type Props = {
  label: string;
  placeholder: string;
  multiple?: boolean;
  inputValue: string;
  // eslint-disable-next-line no-unused-vars
  setInputValue: (val: string) => void;
  hint?: string;
  min?: number;
  max?: number;
  mb?: number;
};

const StyledInputText = styled.div`
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
  ${(props: Props) =>
    props.hint &&
    css`
      input {
        margin-bottom: 7px;
      }
    `};
  & > input,
  & > textarea {
    border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
    font-size: 16px;
    outline: none;
    resize: none;
    width: 100%;
    padding: 10px 16px;
    border-radius: 6px;
    &::placeholder {
      font-size: 16px;
    }
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.greenBg};
    }
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.blackLabel};
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`;

const Textarea = styled.textarea`
  min-height: 120px;
`;

export const InputText = (props: Props) => {
  const {
    label,
    placeholder,
    multiple = false,
    inputValue,
    setInputValue,
    min,
    max,
    hint,
  } = props;
  const { graySubtitle } = theme.colors;

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    if (
      (!min || (min && +e.target.value >= min) || e.target.value === '') &&
      (!max || (max && +e.target.value <= max) || e.target.value === '')
    ) {
      setInputValue(e.target.value);
    }
  };

  return (
    <StyledInputText {...props}>
      <Label>{label}</Label>
      {multiple ? (
        <Textarea
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      ) : (
        <>
          <input
            type="text"
            maxLength={140}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
          {hint && (
            <Typography fz={12} lh={14} color={graySubtitle} mb={24}>
              {hint}
            </Typography>
          )}
        </>
      )}
    </StyledInputText>
  );
};
