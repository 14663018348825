import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import achievementExmaple1 from '../assets/images/achievementExmaple1.png';
import achievementExmaple2 from '../assets/images/achievementExmaple2.png';

import {
  Achievement,
  ConfirmButton,
  PageHeader,
  PageWrapper,
  PageLeftHalf,
  PageRightHalf,
  InputText,
  InputSelect,
  InputFile,
  Typography,
} from '../components';
import { SelectData } from '../models';
import { productService, actionService, achievementService } from '../services';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';

const StyledRightContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Box = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayBorderBox};
  width: 100%;
  max-width: 478px;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 89px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExamplesTitle = styled.div`
  font-family: 'SB Sans Text';
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  font-weight: 500;
  margin-bottom: 24px;
`;

const Examples = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 300px;
  margin-bottom: 4px;
  & > div {
    margin-bottom: 8px;
  }
`;

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

export const AchievementPage = () => {
  const isSuperAdmin = useCheckIsSuperAdmin();
  const navigate = useNavigate();
  const reader = new FileReader();

  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [actionsData, setActionsData] = useState<SelectData[]>([]);

  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [action, setAction] = useState({ id: '0', name: 'Выберите действие' });
  // const [rarity, setRarity] = useState({ id: '0', name: 'Выберите редкость' });
  const [name, setName] = useState('');
  const [descriptionAfterReceive, setDescriptionAfterReceive] = useState('');
  const [descriptionToReceive, setDescriptionToReceive] = useState('');
  const [eventsCount, setEventsCount] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [showMetodology, setShowMetodology] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);

  reader.onload = (e: ProgressEvent<FileReader>) => {
    if (typeof e.target?.result === 'string') {
      setFileUrl(e.target?.result);
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
        disabled: el.id !== 4 && localStorage.getItem('isDevices') === 'true',
      }));
      setProductsData(products);
      if (!isSuperAdmin)
        setProduct(products[products.findIndex((el) => el.id === '1')]);
    }
    if (mounted) fetchData();
  }, [isSuperAdmin, mounted]);

  useEffect(() => {
    (async function fetchActions() {
      if (product.id === '0') return;
      const actions = (await actionService.getAll(product.id)).data;
      setActionsData(actions.map((el) => ({ id: el.actionId, name: el.name })));
      setAction({ id: '0', name: 'Выберите действие' });
    })();
  }, [product]);

  const handleHideMetodology = () =>
    setShowMetodology((prevState) => !prevState);

  const handleAddFile = (fileData: File | null) => {
    if (fileData) {
      setFile(fileData);
      reader.readAsDataURL(fileData);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUrl(null);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileData = e.dataTransfer.files[0];
    reader.readAsDataURL(fileData);
    handleAddFile(fileData);
  };

  const clearState = () => {
    setProduct({ id: '0', name: 'Выберите продукт' });
    setAction({ id: '0', name: 'Выберите действие' });

    setName('');
    setEventsCount('');
    setFile(null);
    setFileUrl(null);
  };

  const handleAchievementCreate = () =>
    new Promise<void>((resolve) => {
      setIsEnabled(false);
      const form = new FormData();
      if (file) form.append('file', file);
      form.append(
        'playbook',
        JSON.stringify({
          actionId: String(action.id),
          actionsCount: +eventsCount,
          name,
          product: { id: +product.id },

          descriptionAfterReceive,
          descriptionToReceive,
        })
      );

      achievementService.create(form).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
          setIsEnabled(true);
        }
      });
    });

  const isValid = () =>
    product.id !== '0' &&
    action.id !== '0' &&
    !!name.length &&
    descriptionAfterReceive !== '' &&
    descriptionToReceive !== '' &&
    !!eventsCount.length &&
    file &&
    isEnabled;

  return (
    <PageWrapper>
      <PageLeftHalf>
        <StyledPageLeftContent>
          <PageHeader
            onClick={() => navigate('/gamification')}
            title="Создание медали"
            mb={isSuperAdmin === false ? 16 : undefined}
          />
          {!isSuperAdmin && (
            <Typography
              color="#000000"
              ff="SB Sans Text"
              fw={600}
              fz={20}
              lh={30}
              mb={24}
            >
              Сберзвук
            </Typography>
          )}
          {isSuperAdmin && (
            <InputSelect
              data={productsData}
              inputValue={product}
              setInputValue={setProduct}
              label="Продукт"
              mb={23}
            />
          )}
          <InputText
            label="Название медали"
            inputValue={name}
            setInputValue={setName}
            placeholder="Введите текст"
            mb={23}
          />
          <InputSelect
            data={actionsData}
            inputValue={action}
            setInputValue={setAction}
            label="Действия"
            mb={23}
          />
          <InputText
            label="Повторы"
            inputValue={eventsCount}
            setInputValue={setEventsCount}
            placeholder="Введите количество повторов"
            min={1}
            mb={40}
          />
          <InputText
            label="Цель задания"
            inputValue={descriptionToReceive}
            setInputValue={setDescriptionToReceive}
            placeholder="Что нужно сделать для получения"
            mb={23}
          />
          <InputText
            label="За что получил"
            inputValue={descriptionAfterReceive}
            setInputValue={setDescriptionAfterReceive}
            placeholder="Что сделал чтобы получить"
            mb={40}
          />

          <InputFile
            label="Иллюстрация"
            placeholder="Загрузите в это поле изображение в формате PNG или JPG"
            handleAddFile={handleAddFile}
            handleDropFile={handleDropFile}
            fileUrl={fileUrl}
            file={file}
            handleFileRemove={handleFileRemove}
          />
          <ConfirmButton
            onClick={handleAchievementCreate}
            text="Создать медаль"
            mt={40}
            modalMessage="Медаль создана"
            modalBtnText="Хорошо"
            disable={!isValid()}
          />
        </StyledPageLeftContent>
      </PageLeftHalf>
      {showMetodology && (
        <PageRightHalf>
          <StyledRightContent>
            <Box>
              <Achievement achievementImg={fileUrl ?? undefined}>
                {name}
              </Achievement>
            </Box>
            <ExamplesTitle>Хорошие примеры</ExamplesTitle>
            <Examples>
              <Achievement achievementImg={achievementExmaple1 as string}>
                Как курьер...
              </Achievement>

              <Achievement achievementImg={achievementExmaple2 as string}>
                Бумбокс
              </Achievement>
            </Examples>
          </StyledRightContent>
        </PageRightHalf>
      )}
      <QuestionLabel onClick={handleHideMetodology} />
    </PageWrapper>
  );
};
