import styled, { css } from 'styled-components';
import { useState } from 'react';
import {
  Achievement,
  Button,
  SberReward,
  Indicator,
  Task,
} from '../components';
import achievementImg from '../assets/images/achievement.png';
import cityMobil from '../assets/images/cityMobil.png';
import { theme } from '../theme';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import { Warning } from '../components/common/Warning';

const StyledGamificationWrapper = styled.div`
  padding: 26px 94px 26px 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LeftHalf = styled.div`
  max-width: 294px;
`;

const Title = styled.div`
  font-family: 'SB Sans Text';
  margin-top: 36px;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.graySubtitle};
  font-family: 'SB Sans Text';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
`;

const RightHalf = styled.div`
  border: 1px dashed ${(props) => props.theme.colors.grayHint};
  max-width: 505px;
  min-width: 505px;
  border-radius: 8px;
  padding: 36px 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    max-width: 431px;
    width: 100%;
  }
`;

const HintTitle = styled.div`
  color: ${(props) => props.theme.colors.blackLabel};
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
`;

const HintName = styled.div`
  margin-top: 32px;
  font-family: 'SB Sans Text';
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 16px;
`;

const HintDescription = styled.div`
  color: ${(props) => props.theme.colors.blackLabel};
  font-family: 'SB Sans Text';
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
`;

const HintBlock = styled.div`
  ${(props: { horizontal?: boolean }) =>
    props.horizontal &&
    css`
      flex-direction: column;
    `}
  min-height: ${(props: { horizontal?: boolean }) =>
    props.horizontal ? '292px' : '180px'};
  align-items: ${(props: { horizontal?: boolean }) =>
    props.horizontal ? 'flex-start' : 'center'};
  font-size: 20px;
  letter-spacing: -0.01em;
  padding: 22px;
  color: #000000;
  background-color: #f4f4f4;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Rewards = styled.div`
  display: flex;
`;

const Steps = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  align-items: center;
`;

const StyledIndicator = styled.div`
  ${(props: { hidden?: boolean }) =>
    props.hidden &&
    css`
      display: block;
      visibility: hidden;
    `}
  width: ${(props: { hidden?: boolean }) => (props.hidden ? '60px' : '124px')};
`;

const Step = styled.div`
  color: ${(props) => props.theme.colors.grayHint};
  background-color: ${(props) => props.theme.colors.blackLabel};
  ${(propList: { active?: boolean }) =>
    propList.active &&
    css`
      background-color: ${(props) => props.theme.colors.greenBg};
      color: #151515;
    `}
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'SB Sans Text';
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  margin: 0 4px;
`;

const StyledStepReward = styled.div`
  margin-right: 4px;
`;

export function GamificationPage() {
  const isSuperAdmin = useCheckIsSuperAdmin();
  const isDevices = localStorage.getItem('isDevices') === 'true';

  const { grayBgButton, questionDarkPurpleBg, blackLabel } = theme.colors;

  const [showMetodology, setShowMetodology] = useState(true);

  const handleHideMetodology = () =>
    setShowMetodology((prevState) => !prevState);

  return (
    <StyledGamificationWrapper>
      <LeftHalf>
        <Title>
          Создание
          <br />
          геймификации
        </Title>
        <Label>Выберите тип геймификации</Label>
        <Button color={grayBgButton} text="Задание" link="/task" noBorder />
        {isSuperAdmin && (
          <Button
            color={grayBgButton}
            text="Активация заданий"
            link="/task-activation"
            noBorder
            disable={isDevices}
          />
        )}
        <Button
          color={grayBgButton}
          text="Цепочка заданий"
          link="/task-chain"
          noBorder
        />
        <Button
          color={grayBgButton}
          text="Активация цепочки заданий"
          link="/task-chain-activation"
          noBorder
        />
        <Button
          color={grayBgButton}
          text="Награда за цепочку заданий"
          link="/map-reward-create"
          noBorder
        />
        <Button color={grayBgButton} text="Карта" link="/map-create" noBorder />
        <Button
          color={grayBgButton}
          text="Медаль"
          link="/achievement"
          noBorder
        />
        <Button
          color={grayBgButton}
          text="Сезонная награда"
          link="/reward-create"
          noBorder
          disable={isDevices}
        />
        {isSuperAdmin && (
          <Button
            color={grayBgButton}
            text="Сезон"
            link="/season-create"
            noBorder
            disable={isDevices}
          />
        )}
        {!isSuperAdmin && (
          <Button
            color={grayBgButton}
            text="Спецпроект"
            link="/specproject"
            noBorder
            disable={isDevices}
          />
        )}
        {isSuperAdmin && (
          <Button
            color={grayBgButton}
            text="Управление сезонами"
            link="/management"
            noBorder
            disable={isDevices}
          />
        )}

        {isSuperAdmin && (
          <Button
            color={grayBgButton}
            text="Все задания и медали"
            link="/tasks-and-medals"
            noBorder
            disable={isDevices}
          />
        )}
        {isSuperAdmin && (
          <Button
            color={grayBgButton}
            text="Метрики"
            link="/metrics"
            noBorder
            disable={isDevices}
          />
        )}
      </LeftHalf>
      {showMetodology && (
        <RightHalf>
          <Warning />
          <HintTitle>Подсказка из методологии</HintTitle>
          <HintName>Медаль</HintName>
          <HintDescription>
            Длинное описание того, что такое медали, как они работают, для чего
            нужны, на что влияют.
          </HintDescription>
          <HintBlock>
            <Achievement achievementImg={achievementImg as string}>
              Коллекционер плейлистов
            </Achievement>
          </HintBlock>
          <HintName>Задание</HintName>
          <HintDescription>
            Длинное описание того, что такое медали, как они работают, для чего
            нужны, на что влияют.
          </HintDescription>
          <HintBlock>
            <Task
              questionImg={cityMobil as string}
              title=""
              description="Прочитать 5 статей"
              stepsDone="2"
              steps="5"
              product="Wellness"
              xp={200}
              bg={questionDarkPurpleBg}
              duration={5}
            />
          </HintBlock>
          <HintName>Сезон</HintName>
          <HintDescription>
            Длинное описание того, что такое медали, как они работают, для чего
            нужны, на что влияют.
          </HintDescription>
          <HintBlock horizontal>
            <Rewards>
              <StyledStepReward>
                <SberReward btnText="Забрать" active>
                  СберПрайм
                  <br />
                  на 7 дней
                </SberReward>
              </StyledStepReward>
              <StyledStepReward>
                <SberReward btnText="200 XP">
                  Подписка СберЗвук на 7 дней
                </SberReward>
              </StyledStepReward>
              <StyledStepReward>
                <SberReward btnText="300 XP">
                  СберПрайм
                  <br />
                  на 14 дней
                </SberReward>
              </StyledStepReward>
            </Rewards>
            <Steps>
              <StyledIndicator hidden>
                <Indicator small currentXP="10" levelXP="10" bg={blackLabel} />
              </StyledIndicator>
              <Step active>1</Step>
              <StyledIndicator>
                <Indicator small currentXP="8" levelXP="10" bg={blackLabel} />
              </StyledIndicator>
              <Step>2</Step>
              <StyledIndicator>
                <Indicator small currentXP="0" levelXP="10" bg={blackLabel} />
              </StyledIndicator>
              <Step>3</Step>
              <StyledIndicator>
                <Indicator small currentXP="0" levelXP="10" bg={blackLabel} />
              </StyledIndicator>
            </Steps>
          </HintBlock>
        </RightHalf>
      )}
      <QuestionLabel onClick={handleHideMetodology} />
    </StyledGamificationWrapper>
  );
}
