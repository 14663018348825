import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  ConfirmButton,
  PageHeader,
  FileLabel,
  InputText,
  InputFile,
} from '../components';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { specprojectService } from '../services';

const StyledClaimCreateWrapper = styled.div`
  margin: 24px 120px;
  max-width: 436px;
  width: 100%;
`;

const TextWithFileLoad = styled.div`
  position: relative;
  & > div:last-child > div {
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
  }
`;

export const SpecprojectPage = () => {
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');

  const handleFileRemove = () => {
    setFile(null);
  };

  const clearState = () => {
    setFile(null);
    setName('');
    setEmail('');
    setPhoneNumber('');
    setDescription('');
  };

  const handleSpecprojectCreate = () =>
    new Promise<void>((resolve) => {
      const form = new FormData();
      if (file) form.append('file', file);
      form.append(
        'playbook',
        JSON.stringify({
          product: { id: 1 },
          fullName: name,
          email,
          phoneNumber,
          description,
        })
      );

      specprojectService.create(form).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
        }
      });
    });

  const isValid = () =>
    !!name.length &&
    !!email.length &&
    !!phoneNumber.length &&
    (!!description.length || file);

  return (
    <StyledClaimCreateWrapper>
      <PageHeader
        onClick={() => navigate('/gamification')}
        title="Создание спецпроекта"
      />
      <InputText
        inputValue={name}
        setInputValue={setName}
        label="Ваше ФИО"
        placeholder="Введите ФИО"
        mb={23}
      />
      <InputText
        inputValue={email}
        setInputValue={setEmail}
        label="Почта"
        placeholder="Введите почту"
        mb={23}
      />
      <InputText
        inputValue={phoneNumber}
        setInputValue={setPhoneNumber}
        label="Номер мобильного телефона"
        placeholder="Введите номер"
        mb={23}
      />
      <TextWithFileLoad>
        <InputText
          inputValue={description}
          setInputValue={setDescription}
          label="Краткое описание спецпроекта"
          placeholder="Введите текст"
          multiple
        />
        <InputFile file={file} handleAddFile={setFile} />
      </TextWithFileLoad>
      {file && <FileLabel file={file} onRemove={handleFileRemove} />}
      <ConfirmButton
        onClick={handleSpecprojectCreate}
        text="Отправить заявку"
        mt={40}
        modalMessage="Заявка на спецпроект создана, ответ придёт на почту в течение 3 рабочих дней"
        modalBtnText="Хорошо"
        disable={!isValid()}
      />
      <QuestionLabel />
    </StyledClaimCreateWrapper>
  );
};
