import { createGlobalStyle } from 'styled-components';
import fontsCss from './css/fonts.module.css';

const FontStyles = createGlobalStyle`
// FOUT bug fix
${fontsCss}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SB Sans Interface', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
button {
  background: none;
  border: none;
}
img {
    max-width: 100%;
    object-fit: cover;
}
`;

export default FontStyles;
