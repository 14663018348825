import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmButton,
  InputSelect,
  InputText,
  InputFile,
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
  SberReward,
  Typography,
} from '../components';
import { SelectData } from '../models';
import { achievementService, productService, seasonService } from '../services';
import addIcon from '../assets/images/add.png';
import removeIcon from '../assets/images/remove.png';
import { theme } from '../theme';
import { getAwardNumeration } from '../utils';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import { Level, LevelsWrapper } from '../components/common/RewardCreate';

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

const ChooseReward = styled.div`
  ${(props: { marginTop?: boolean }) =>
    props.marginTop &&
    css`
      margin-top: 40px;
    `}
  font-family: 'SB Sans Text';
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

const RewardTitle = styled.div`
  font-family: 'SB Sans Text';
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 18px;
`;

const RewardSubtitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #2e2e30;
  margin-bottom: 16px;
`;

const Hint = styled.div`
  display: flex;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 24px;
  }
  & > div:first-child {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
`;

const HintLabel = styled.div`
  color: ${(props) => props.theme.colors.graySubtitle};
  margin-left: 10px;
  font-size: 16px;
  line-height: 24px;
`;

const Gray = styled.div`
  background-color: ${(props) => props.theme.colors.grayBorderButton};
`;

const White = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
`;

const Green = styled.div`
  background-color: ${(props) => props.theme.colors.greenBg};
`;

const StyledRewardBtn = styled.div`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  color: ${(props) => props.theme.colors.blackLabel};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  div {
    margin-right: 13px;
  }
`;

const StyledRewardRemoveBtn = styled(StyledRewardBtn)`
  background-color: ${(props) => props.theme.colors.orange};
  margin-bottom: ${(props: { mb: number }) => `${props.mb}px;`};
  color: #ffffff;
`;

const StyledRightContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;

const TitleWrapper = styled.div`
  max-width: 397px;
  text-align: center;
`;

const Box = styled.div`
  width: 544px;
  border: 1px solid ${(props) => props.theme.colors.grayBorderBox};
  border-radius: 8px;
  padding: 76px;
  margin-top: 8px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;

const defaultAward = {
  description: '',
  promocode: '',
  activation: '',
  fileUrl: '',
  medalPlaybookId: 0,
  imageFileKey: '',
  awardDescription: '',
};

export const RewardCreatePage = () => {
  const navigate = useNavigate();
  const { graySubtitle, previewSubtitle } = theme.colors;
  const isSuperAdmin = useCheckIsSuperAdmin();
  const reader = new FileReader();
  const [file, setFile] = useState<File | null>(null);
  const [mounted, setMounted] = useState(false);
  const [seasonsData, setSeasonsData] = useState<SelectData[]>([]);
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [awardData, setAwardData] = useState([defaultAward]);
  const [places, setPlaces] = useState<
    {
      status: string;
      awardIndex?: number;
    }[]
  >([]);
  const [playbookId, setPlaybookId] = useState<number>();
  const [season, setSeason] = useState({ id: '0', name: 'Выберите Сезон' });
  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [medal, setMedal] = useState({
    id: '0',
    name: 'Выберите медаль',
    playbookId: 0,
    imageUrl: '',
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showMetodology, setShowMetodology] = useState(true);
  const [itemReward, setItemReward] = useState(false);
  const [medalReward, setMedalReward] = useState(false);
  const [itemRewardChecked, setItemRewardChecked] = useState(false);
  const [medalRewardChecked, setMedalRewardChecked] = useState(false);
  const [medalData, setMedalData] = useState([
    { id: '0', name: 'Медаль 1', playbookId: 1, imageUrl: '' },
    { id: '0', name: 'Медаль 2', playbookId: 2, imageUrl: '' },
    { id: '0', name: 'Медаль 3', playbookId: 3, imageUrl: '' },
  ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
      }));
      setProductsData(products);
      const medalsRes = (await achievementService.getAll(1, 9999)).data;
      const medals = medalsRes.map((el) => ({
        id: String(el.elementId),
        name: el.name,
        playbookId: el.playbookId,
        imageUrl: el.imageUrl,
      }));
      setMedalData(medals);
      if (!isSuperAdmin)
        setProduct(products[products.findIndex((el) => el.id === '1')]);

      const seasonsRes = (await seasonService.getAll()).data;
      const seasons = seasonsRes.map((el) => ({
        id: String(el.playbookId),
        name: el.name,
      }));
      setSeasonsData(seasons);
    }
    if (mounted) {
      fetchData();
    }
  }, [isSuperAdmin, mounted]);

  useEffect(() => {
    async function updateSeasonData(id: string) {
      const seasonData = (await seasonService.getByPlaybookId(+id)).data;
      setPlaces(
        seasonData.seasonElementsGetDtos.map((el) => ({
          status: el.seasonElementsStatus,
        }))
      );
      setPlaybookId(seasonData.playbookId);
    }
    const curSeason = seasonsData.find((se) => se.id === season.id);
    if (mounted && season.id !== '0' && curSeason) {
      updateSeasonData(curSeason.id);
    }
  }, [season, seasonsData, mounted]);

  const handleHideMetodology = () =>
    setShowMetodology((prevState) => !prevState);

  const handleDescriptionChange = ({
    description,
    awardIndex,
  }: {
    description: string;
    awardIndex: number;
  }) => {
    setCurrentIndex(awardIndex);
    setAwardData((data) =>
      data.map((item, index) => {
        if (index !== awardIndex) return item;
        return {
          ...item,
          description,
        };
      })
    );
  };
  const handleAwardDescriptionChange = ({
    awardDescription,
    awardIndex,
  }: {
    awardDescription: string;
    awardIndex: number;
  }) => {
    setCurrentIndex(awardIndex);
    setAwardData((data) =>
      data.map((item, index) => {
        if (index !== awardIndex) return item;
        return {
          ...item,
          awardDescription,
        };
      })
    );
  };
  const handleImageChange = ({
    imageFileKey,
    awardIndex,
  }: {
    imageFileKey: any;
    awardIndex: number;
  }) => {
    setCurrentIndex(awardIndex);
    setAwardData((data) =>
      data.map((item, index) => {
        if (index !== awardIndex) return item;
        return {
          ...item,
          imageFileKey,
        };
      })
    );
  };
  const handleActivationChange = ({
    activation,
    awardIndex,
  }: {
    activation: string;
    awardIndex: number;
  }) => {
    setCurrentIndex(awardIndex);
    setAwardData((data) =>
      data.map((item, index) => {
        if (index !== awardIndex) return item;
        return {
          ...item,
          activation,
        };
      })
    );
  };

  const handlePromocodeChange = ({
    promocode,
    awardIndex,
  }: {
    promocode: string;
    awardIndex: number;
  }) => {
    setCurrentIndex(awardIndex);
    setAwardData((data) =>
      data.map((item, index) => {
        if (index !== awardIndex) return item;
        return {
          ...item,
          promocode,
        };
      })
    );
  };
  const handlePlaybookIdChange = ({
    medalPlaybookId,
    awardIndex,
  }: {
    medalPlaybookId: number;
    awardIndex: number;
  }) => {
    setCurrentIndex(awardIndex);
    setAwardData((data) =>
      data.map((item, index) => {
        if (index !== awardIndex) return item;
        return {
          ...item,
          medalPlaybookId,
        };
      })
    );
  };

  const clearState = () => {
    setProduct({ id: '0', name: 'Выберите продукт' });
    setCurrentIndex(0);
    setAwardData([defaultAward]);
    setPlaces((prevPlaces) =>
      prevPlaces.map((pl) =>
        pl.status === 'SELECTED' ? { status: 'TAKEN' } : pl
      )
    );
  };

  const handleRewardCreate = () =>
    new Promise<void>((resolve) => {
      if (!playbookId) return;
      Promise.all(
        awardData.map(async (el, index) => {
          const idx = places.findIndex((place) => place.awardIndex === index);
          const award = {
            awards: [
              {
                description: el.description,
                product: { id: +product.id, name: product.name },
                promocode: el.promocode,
                imageFileKey: el.imageFileKey,
                activationLink: el.activation,
                awardDescription: el.awardDescription,
                playbookId: el.medalPlaybookId,
              },
            ],
            level: idx !== -1 ? idx + 1 : 1,
          };
          return seasonService.createAward(award, playbookId);
        })
      ).then((data) => {
        if (data.every((el) => el.status === 200)) {
          clearState();
          resolve();
        }
      });
    });

  const handleAddFile = (fileData: any, awardIndex: any) => {
    const form = new FormData();
    let fileUrl: string;
    let imageFileKey: string;
    form.append('file', fileData);
    seasonService.uploadImage(form).then((response) => {
      imageFileKey = response.data;
      setAwardData((data) =>
        data.map((item, index) => {
          if (index !== awardIndex) return item;
          return {
            ...item,
            imageFileKey,
          };
        })
      );
    });
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (typeof e.target?.result === 'string') {
        fileUrl = e.target?.result;
        setAwardData((data) =>
          data.map((item, index) => {
            if (index !== awardIndex) return item;
            return {
              ...item,
              fileUrl,
            };
          })
        );
      }
    };
    if (fileData) {
      handleImageChange({ imageFileKey: fileData, awardIndex });
      setFile(fileData);
      reader.readAsDataURL(fileData);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileData = e.dataTransfer.files[0];
    reader.readAsDataURL(fileData);
  };

  const handleLevelClick = (awardIndex: number, placeIndex: number) => {
    setPlaces((placesPrev) =>
      placesPrev.map((placePrevItem, placePrevIndex) => {
        const isSelected = placePrevItem.status === 'SELECTED';
        const isFree = placePrevItem.status === 'FREE';
        const sameSeason = placePrevItem.awardIndex === awardIndex;
        const samePlaceIndex = placePrevIndex === placeIndex;
        const placeForSeasonTaken =
          placesPrev.find(
            (item) => isSelected && item.awardIndex === awardIndex
          ) && sameSeason;

        const selectedPlace = places[placeIndex];
        if (
          (selectedPlace.status === 'SELECTED' &&
            selectedPlace.awardIndex !== awardIndex) ||
          selectedPlace.status === 'TAKEN'
        )
          return placePrevItem;

        let newStatus = placePrevItem.status;
        if (isFree && samePlaceIndex) newStatus = 'SELECTED';
        if (isSelected && samePlaceIndex && sameSeason) newStatus = 'FREE';
        if (isSelected && !samePlaceIndex && sameSeason) newStatus = 'FREE';
        if (isSelected && !samePlaceIndex && placeForSeasonTaken)
          newStatus = 'FREE';

        let newAwardIndex;
        if (newStatus === 'SELECTED' && samePlaceIndex)
          newAwardIndex = awardIndex;
        if (newStatus === 'SELECTED' && !samePlaceIndex)
          newAwardIndex = placePrevItem.awardIndex;

        return {
          ...placePrevItem,
          awardIndex: newAwardIndex,
          status: newStatus,
        };
      })
    );
  };

  const handleRemoveAward = (awardIndex: number, curIndex: number) => {
    if (awardIndex <= curIndex) setCurrentIndex(curIndex - 1);
    const newAwardData = awardData.filter((el, index) => index !== awardIndex);
    setAwardData(newAwardData);
    setPlaces((prevPlaces) =>
      prevPlaces.map((prevPlaceItem) =>
        prevPlaceItem.awardIndex !== awardIndex
          ? {
              ...prevPlaceItem,
              awardIndex:
                prevPlaceItem.awardIndex &&
                prevPlaceItem.awardIndex > awardIndex
                  ? prevPlaceItem.awardIndex - 1
                  : prevPlaceItem.awardIndex,
            }
          : {
              status: 'FREE',
              awardIndex: undefined,
            }
      )
    );
  };

  const getNumeration = (index: number) => getAwardNumeration[index];

  const isActiveAward = (index: number) =>
    awardData[index]?.description && awardData[index]?.promocode;

  const isValid = () =>
    product.id !== '0' &&
    places.filter((place) => place.status === 'SELECTED').length ===
      awardData.length &&
    awardData.every(
      (award) =>
        !!award.description.length &&
        !!award.promocode.length &&
        !!award.activation.length
    );

  return (
    <PageWrapper>
      <PageLeftHalf showMetodology={showMetodology}>
        <StyledPageLeftContent>
          <PageHeader
            onClick={() => navigate('/gamification')}
            title="Создание сезонной награды"
          />
          <InputSelect
            data={seasonsData}
            inputValue={season}
            setInputValue={setSeason}
            label="Сезон"
            mb={23}
          />
          {season.id !== '0' && isSuperAdmin && (
            <InputSelect
              data={productsData}
              inputValue={product}
              setInputValue={setProduct}
              label="Продукт"
              mb={23}
            />
          )}
          {season.id !== '0' &&
            awardData.map((award, awardIndex) => {
              const removeBtnProps = {
                mb: awardIndex === awardData.length - 1 ? 8 : 40,
              };

              return (
                <>
                  <ChooseReward marginTop={!isSuperAdmin}>
                    Выберите {getNumeration(awardIndex).accusative} награду
                  </ChooseReward>
                  <RewardTitle>
                    {getNumeration(awardIndex).nominative} награда в сезоне
                  </RewardTitle>
                  <RewardSubtitle>Выберите место в Сезоне</RewardSubtitle>
                  <LevelsWrapper>
                    {places.map((el, placeIndex) => (
                      <Level
                        type="button"
                        free={el.status === 'FREE'}
                        selected={
                          el.status === 'SELECTED' &&
                          awardIndex === el.awardIndex
                        }
                        onClick={() => handleLevelClick(awardIndex, placeIndex)}
                      >
                        {placeIndex + 1}
                      </Level>
                    ))}
                  </LevelsWrapper>
                  <div>
                    <Hint>
                      <Gray />
                      <HintLabel>Место занято</HintLabel>
                    </Hint>
                    <Hint>
                      <White />
                      <HintLabel>Место свободно</HintLabel>
                    </Hint>
                    <Hint>
                      <Green />
                      <HintLabel>Место выбрано</HintLabel>
                    </Hint>
                  </div>
                  <InputText
                    inputValue={award.description}
                    setInputValue={(description) =>
                      handleDescriptionChange({
                        description,
                        awardIndex,
                      })
                    }
                    multiple
                    label="Название"
                    placeholder="Введите название награды"
                    mb={23}
                  />
                  <InputText
                    inputValue={award.awardDescription}
                    setInputValue={(awardDescription) =>
                      handleAwardDescriptionChange({
                        awardDescription,
                        awardIndex,
                      })
                    }
                    multiple
                    label="Описание награды"
                    placeholder="Введите текст награды"
                    mb={23}
                  />
                  {!medalReward && (
                    <InputFile
                      label="Иллюстрация"
                      placeholder="Загрузите в это поле изображение в формате PNG или JPG"
                      handleAddFile={(e) => handleAddFile(e, awardIndex)}
                      handleDropFile={handleDropFile}
                      fileUrl={award.fileUrl}
                      file={file}
                      handleFileRemove={handleFileRemove}
                    />
                  )}

                  <Typography mt={24} fz={16} fw={200} lh={14} mb={24}>
                    {' '}
                    <input
                      checked={itemRewardChecked}
                      id="item_reward"
                      onChange={() => {
                        handlePromocodeChange({
                          promocode: '',
                          awardIndex,
                        });
                        setMedalRewardChecked(false);
                        setMedalReward(false);
                        if (!itemReward) {
                          setItemRewardChecked(true);
                          handleActivationChange({
                            activation: 'ITEM_REWARD',
                            awardIndex,
                          });
                        }
                        if (itemReward) {
                          setItemRewardChecked(false);
                          handleActivationChange({
                            activation: '',
                            awardIndex,
                          });
                        }
                        setItemReward(!itemReward);
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="item_reward">{` Вещь в качестве награды`}</label>
                    <input
                      style={{ marginLeft: 20 }}
                      id="medal_reward"
                      checked={medalRewardChecked}
                      onChange={() => {
                        handlePromocodeChange({
                          promocode: '',
                          awardIndex,
                        });
                        setMedal({
                          id: '0',
                          name: 'Выберите медаль',
                          playbookId: 0,
                          imageUrl: '',
                        });
                        setItemReward(false);
                        setItemRewardChecked(false);
                        if (!medalReward) {
                          handleActivationChange({
                            activation: 'MEDAL_REWARD',
                            awardIndex,
                          });
                          setMedalRewardChecked(true);
                        }
                        if (medalReward) {
                          handleActivationChange({
                            activation: '',
                            awardIndex,
                          });
                          setMedalRewardChecked(false);
                        }
                        setMedalReward(!medalReward);
                      }}
                      type="checkbox"
                    />
                    <label htmlFor="medal_reward">
                      {` Медаль в качестве награды`}
                    </label>
                  </Typography>

                  {!itemReward && !medalReward && (
                    <>
                      <InputText
                        inputValue={award.promocode}
                        setInputValue={(promocode) =>
                          handlePromocodeChange({
                            promocode,
                            awardIndex,
                          })
                        }
                        label="Промокод"
                        placeholder="Введите промокод"
                        mb={7}
                      />
                      <Typography fz={12} lh={14} color={graySubtitle} mb={24}>
                        Данный промокод будут получать пользователи
                      </Typography>
                      <InputText
                        inputValue={award.activation}
                        setInputValue={(activation) =>
                          handleActivationChange({
                            activation,
                            awardIndex,
                          })
                        }
                        label="Активация"
                        placeholder="Ссылка на страницу с активацией промокода"
                        mb={23}
                      />
                    </>
                  )}
                  {medalReward && (
                    <InputSelect
                      data={medalData}
                      inputValue={medal}
                      setInputValue={(medals) => {
                        setMedal(medals);
                        const url = 'https://static.playground.sysdyn.ru/';
                        const regExp = new RegExp(url, 'g');
                        const imgUrl = medals.imageUrl.replace(regExp, '');
                        handleImageChange({
                          imageFileKey: imgUrl,
                          awardIndex,
                        });
                        handlePlaybookIdChange({
                          medalPlaybookId: medals.playbookId,
                          awardIndex,
                        });
                        handlePromocodeChange({
                          promocode: medals.id,
                          awardIndex,
                        });
                      }}
                      label="Медаль"
                      mb={23}
                    />
                  )}
                  {itemReward && (
                    <>
                      {' '}
                      <InputText
                        multiple
                        inputValue={award.promocode}
                        setInputValue={(promocode) =>
                          handlePromocodeChange({
                            promocode,
                            awardIndex,
                          })
                        }
                        label="Айди вещи"
                        placeholder="Введите ID вещи в качестве награды"
                        mb={23}
                      />
                    </>
                  )}
                  {awardIndex > 0 && (
                    <StyledRewardRemoveBtn
                      {...removeBtnProps}
                      onClick={() =>
                        handleRemoveAward(awardIndex, currentIndex)
                      }
                    >
                      <div>Удалить награду</div>
                      <img src={removeIcon as string} alt="remove" />
                    </StyledRewardRemoveBtn>
                  )}
                </>
              );
            })}
          {season.id !== '0' && (
            <>
              <StyledRewardBtn
                onClick={() =>
                  awardData.length < 62 &&
                  setAwardData((data) => [...data, defaultAward])
                }
              >
                <div>Добавить награду</div>
                <img src={addIcon as string} alt="add" />
              </StyledRewardBtn>
              <ConfirmButton
                onClick={handleRewardCreate}
                text="Создать сезонную награду"
                modalMessage="Вы создали сезонную награду"
                modalBtnText="Хорошо"
                mt={40}
                disable={!isValid()}
              />
            </>
          )}
        </StyledPageLeftContent>
      </PageLeftHalf>
      {showMetodology && (
        <PageRightHalf hiddenTitle>
          <StyledRightContent>
            <TitleWrapper>
              <Typography ff="SB Sans Text" fw={500} fz={18} lh={32} mb={10}>
                Предварительный просмотр
              </Typography>
              <Typography
                ff="SB Sans Text"
                fz={16}
                lh={24}
                mb={32}
                color={previewSubtitle}
              >
                Меняйте пункты в заявке, вместе с ними будет меняться и карточка
                сезона
              </Typography>
            </TitleWrapper>
            <Box>
              <SberReward
                btnText={isActiveAward(currentIndex) ? 'Забрать' : '200 XP'}
                active={!!isActiveAward(currentIndex)}
              >
                {awardData[currentIndex]?.description ?? ''}
              </SberReward>
            </Box>
          </StyledRightContent>
        </PageRightHalf>
      )}
      <QuestionLabel onClick={handleHideMetodology} />
    </PageWrapper>
  );
};
