import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledPageWrapper = styled.div`
  padding: 24px 64px 26px 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  min-height: 100%;
`;

export const PageWrapper = ({ children }: { children: ReactNode }) => (
  <StyledPageWrapper>{children}</StyledPageWrapper>
);
