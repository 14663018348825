export const ClipIcon = ({ onClick }: { onClick: () => void }) => (
  <svg
    onClick={onClick}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3238 2.91639C13.1121 1.69996 11.1758 1.69996 9.96408 2.91638L3.40344 9.50228C1.4682 11.445 1.4682 14.6418 3.40344 16.5845C5.27815 18.4664 8.2737 18.4664 10.1484 16.5845L16.3416 10.3674C16.7271 9.9805 17.345 9.98763 17.7218 10.3833C18.0987 10.779 18.0917 11.4134 17.7063 11.8003L11.5131 18.0174C8.87978 20.6609 4.67207 20.6609 2.03876 18.0174C-0.679586 15.2886 -0.679585 10.7982 2.03876 8.06936L8.59939 1.48346C10.5698 -0.494491 13.7182 -0.494486 15.6885 1.48347C17.7225 3.52529 17.7225 6.88524 15.6885 8.92706L9.37739 15.2625C8.20255 16.4419 6.3253 16.4419 5.15047 15.2625C3.9377 14.0451 3.9377 12.0417 5.15047 10.8243L10.4856 5.46857C10.871 5.08167 11.489 5.0888 11.8658 5.48449C12.2427 5.88018 12.2357 6.51459 11.8503 6.90149L6.51516 12.2572C6.08549 12.6885 6.08549 13.3983 6.51516 13.8296C6.93138 14.2474 7.59647 14.2474 8.0127 13.8296L14.3238 7.49414C15.5747 6.23844 15.5747 4.17209 14.3238 2.91639Z"
      fill="black"
    />
  </svg>
);
