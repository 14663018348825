import React from 'react';
import styled, { css } from 'styled-components';
import { SberSoundIcon } from '../../assets/icons';

type Props = { btnText: string; children: React.ReactNode; active?: boolean };

const StyledSberReward = styled.div`
  color: ${(props) => props.theme.colors.grayHint};
  background-color: ${(props) => props.theme.colors.blackLabel};
  position: relative;
  height: 206px;
  width: 160px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: -0.02em;
  border-radius: 16px;
  overflow: hidden;
`;

const MainContent = styled.div`
  height: 100%;
  line-height: 17px;
  padding: 0 9px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
`;

const Text = styled.span`
  overflow: hidden;
  width: 100%;
  padding: 0 5px;
  margin-top: 20px;
  min-height: 34px;
  max-height: 34px;
`;

const Footer = styled.div`
  ${(propList: Props) =>
    propList.active &&
    css`
      background-color: ${(props) => props.theme.colors.greenBg};
      cursor: pointer;
      color: #151515;
    `}
  position: relative;
  font-family: 'SB Sans Text';
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  line-height: 18px;
`;

export const SberReward = (props: Props) => {
  const { btnText, active, children } = props;

  return (
    <StyledSberReward {...props}>
      <MainContent>
        <SberSoundIcon active={active} />
        <Text>{children}</Text>
      </MainContent>
      <Footer {...props}>{btnText} </Footer>
    </StyledSberReward>
  );
};
