import { Route, Routes } from 'react-router-dom';
import {
  AchievementPage,
  SpecprojectPage,
  ClaimReviewPage,
  GamificationPage,
  MainPage,
  TaskPage,
  ClaimsPage,
  ClaimPage,
  RewardCreatePage,
  SeasonCreatePage,
  TaskActivationPage,
  TaskChainPage,
  TaskChainActivationPage,
} from '../pages';
import { SeasonManagement } from '../pages/SeasonManagement';

import { TaskAndMedalsPage } from '../pages/TasksAndMedalsPage';
import { MapAwardCreatePage } from '../pages/mapAwardCreatePage';
import { MapCreatePage } from '../pages/MapCreatePage';
import { MetricsPage } from '../pages/MetricsPage';

export const RouteNames = {
  LOGIN: '/login',
  MAIN: '/main',
  GAMIFICATION: '/gamification',
  TASK: '/task',
  TASK_CHAIN: '/task-chain',
  TASK_CHAIN_ACTIVATION: '/task-chain-activation',
  ACHIEVEMENT: '/achievement',
  REWARD_CREATE: '/reward-create',
  SEASON_CREATE: '/season-create',
  CLAIMS: '/claims',
  CLAIM: '/claims/:id',
  CLAIM_REVIEW: '/claim-review',
  SPECPROJECTPAGE: '/specproject',
  SEASON_MANAGEMENT: '/management',
  TASK_ACTIVATION: '/task-activation',
  TASKS_AND_MEDALS: '/tasks-and-medals',
  MAP_REWARD_PAGE: '/map-reward-create',
  MAP_CREATE: '/map-create',
  METRICS_PAGE: '/metrics',
};

export const PrivateRoutes = ({
  isSuperAdmin,
  isDevices,
}: {
  isSuperAdmin: boolean | null;
  isDevices: boolean;
}) => (
  <Routes>
    <Route path="*" element={<GamificationPage />} />
    <Route path={RouteNames.MAIN} element={<MainPage />} />
    <Route path={RouteNames.GAMIFICATION} element={<GamificationPage />} />
    <Route path={RouteNames.TASK} element={<TaskPage />} />
    <Route path={RouteNames.MAP_REWARD_PAGE} element={<MapAwardCreatePage />} />
    <Route path={RouteNames.ACHIEVEMENT} element={<AchievementPage />} />
    <Route path={RouteNames.TASK_CHAIN} element={<TaskChainPage />} />
    <Route path={RouteNames.METRICS_PAGE} element={<MetricsPage />} />
    <Route
      path={RouteNames.TASK_CHAIN_ACTIVATION}
      element={<TaskChainActivationPage />}
    />
    <Route path={RouteNames.MAP_CREATE} element={<MapCreatePage />} />
    {!isDevices && (
      <>
        <Route path={RouteNames.REWARD_CREATE} element={<RewardCreatePage />} />
        {isSuperAdmin && (
          <>
            <Route
              path={RouteNames.CLAIM_REVIEW}
              element={<ClaimReviewPage />}
            />
            <Route
              path={RouteNames.SEASON_CREATE}
              element={<SeasonCreatePage />}
            />

            <Route path={RouteNames.CLAIMS} element={<ClaimsPage />} />
            <Route path={RouteNames.CLAIM} element={<ClaimPage />} />

            <Route
              path={RouteNames.SEASON_MANAGEMENT}
              element={<SeasonManagement />}
            />
            <Route
              path={RouteNames.TASK_ACTIVATION}
              element={<TaskActivationPage />}
            />

            <Route
              path={RouteNames.TASKS_AND_MEDALS}
              element={<TaskAndMedalsPage />}
            />
          </>
        )}
        {!isSuperAdmin && (
          <Route
            path={RouteNames.SPECPROJECTPAGE}
            element={<SpecprojectPage />}
          />
        )}
      </>
    )}
  </Routes>
);
