export const SberSoundIcon = ({ active }: { active?: boolean }) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={active ? '1' : '0.2'}>
      <path
        d="M50.1572 10.8801C51.4874 12.5998 52.6134 14.4681 53.5126 16.4474L28.006 35.2492L17.3459 28.5671V20.5284L28.006 27.2105L50.1572 10.8801Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
      <path
        d="M6.46683 27.9986C6.46683 27.638 6.46683 27.2796 6.49541 26.9234L0.0329943 26.6068C0.0110058 27.0685 9.5323e-06 27.5346 9.5323e-06 28.0052C-0.00302759 31.681 0.719718 35.3213 2.12679 38.7171C3.53385 42.113 5.59756 45.1977 8.19952 47.7942L12.7819 43.2141C10.7787 41.2191 9.18957 38.8478 8.10581 36.2366C7.02205 33.6254 6.46505 30.8257 6.46683 27.9986Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
      <path
        d="M28.0007 6.46663C28.3613 6.46663 28.7197 6.47982 29.0759 6.49741L29.3992 0.0351908C28.9345 0.0117372 28.4683 9.53197e-06 28.0007 9.53197e-06C24.3248 -0.0030275 20.6844 0.719699 17.2884 2.12672C13.8924 3.53375 10.8077 5.59739 8.21106 8.19927L12.7935 12.7815C14.7875 10.7794 17.1575 9.19094 19.7672 8.10724C22.3769 7.02355 25.1749 6.46601 28.0007 6.46663Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
      <path
        d="M27.9999 49.5334C27.6393 49.5334 27.2809 49.5334 26.9247 49.5048L26.6014 55.9648C27.0632 55.9883 27.5294 56 27.9999 56C31.6762 56.0038 35.3169 55.2813 38.7131 53.8738C42.1092 52.4663 45.1938 50.4017 47.7896 47.7986L43.2093 43.2185C41.2147 45.2205 38.8444 46.8089 36.2344 47.8925C33.6243 48.9762 30.826 49.5338 27.9999 49.5334Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
      <path
        d="M40.1408 10.2244L45.5764 6.2116C40.6003 2.18224 34.3886 -0.011185 27.9856 4.28926e-05V6.46666C32.3244 6.45949 36.5631 7.76986 40.1408 10.2244Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
      <path
        d="M56 27.9991C56.0036 26.3168 55.8564 24.6375 55.5602 22.9814L49.5332 27.423C49.5332 27.6143 49.5332 27.8056 49.5332 27.9991C49.5344 31.0078 48.9033 33.9831 47.681 36.7323C46.4586 39.4815 44.6722 41.9432 42.4375 43.9578L46.7846 48.7622C49.6899 46.1402 52.0115 42.9369 53.5991 39.36C55.1866 35.7831 56.0046 31.9124 56 27.9991Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
      <path
        d="M28.0016 49.5321C24.9931 49.5334 22.0177 48.9026 19.2685 47.6807C16.5192 46.4588 14.0574 44.6729 12.0424 42.4388L7.23792 46.7836C9.85995 49.6887 13.0634 52.0103 16.6404 53.5978C20.2174 55.1853 24.0882 56.0033 28.0016 55.9987V49.5321Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
      <path
        d="M13.5603 12.0406L9.2154 7.23627C6.31017 9.85822 3.9885 13.0615 2.40094 16.6384C0.813385 20.2153 -0.0046046 24.086 1.9496e-05 27.9994H6.46684C6.46551 24.9909 7.09627 22.0156 8.31824 19.2665C9.54022 16.5173 11.3262 14.0555 13.5603 12.0406Z"
        fill={active ? '#27C498' : '#AEB1B0'}
      />
    </g>
  </svg>
);
