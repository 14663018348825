/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import styled from 'styled-components';
import { authService } from '../services';

interface MainLoginPageProps {
  onLogin: () => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid #e4e5e7;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 24px;
`;

const Input = styled.input`
  font-size: 24px;
  padding: 5px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #e4e5e7;
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  &:focus {
    outline: 1px solid black;
  }
`;

const Button = styled.button`
  cursor: pointer;
  font-size: 24px;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #e4e5e7;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  &:active {
    background-color: #e4e5e7;
  }
`;

const Error = styled.div`
  color: red;
  height: 25px;
`;

const credentialsData = [
  { login: 'pgadmin', password: '172938' },
  { login: 'sdadmin', password: '403747' },
];

const MainLoginPage: React.FC<MainLoginPageProps> = ({ onLogin }) => {
  const [error, setError] = useState('');

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      credentialsData.find(
        (el) => JSON.stringify(el) === JSON.stringify({ login, password })
      )
    ) {
      setError('');

      authService.login(btoa('super-admin:admin'), 'super').then(() => {
        localStorage.setItem('isDevices', String(login === 'sdadmin'));
        onLogin();
      });
    } else {
      setError('Неправильный логин или пароль');
    }
  };

  return (
    <Wrapper>
      <Form onSubmit={handleLogin}>
        <Label htmlFor="login">
          Логин
          <Input
            autoFocus
            type="text"
            value={login}
            onChange={({ target: { value } }) => setLogin(value)}
          />
        </Label>
        <Label htmlFor="password">
          Пароль
          <Input
            type="password"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
        </Label>
        <Error>{error}</Error>
        <Button type="submit">Войти</Button>
      </Form>
    </Wrapper>
  );
};
export default MainLoginPage;
