import styled from 'styled-components';
import { Button } from '../components';
import { authService } from '../services';

const StyledLoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.grayTitle};
  font-family: 'SB Sans Display';
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Subtitle = styled.div`
  font-family: 'SB Sans Text';
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 40px;
  text-align: center;
`;

export const LoginPage = () => {
  const handleSuperAuth = () =>
    authService.login(btoa('super-admin:admin'), 'super');
  const handleLocalAuth = () =>
    authService.login(btoa('klever-admin:admin'), 'local');

  return (
    <StyledLoginWrapper>
      <Title>Playground</Title>
      <Subtitle>
        Выберите тип
        <br />
        администратора
      </Subtitle>
      <Button
        onClick={handleSuperAuth}
        label="сбер id"
        text="Супер админ Playground"
      />
      <Button
        onClick={handleLocalAuth}
        label="сбер id"
        text="Локальный админ"
      />
    </StyledLoginWrapper>
  );
};
