import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const getAwardNumeration = [
  { nominative: 'Первая', accusative: 'первую' },
  { nominative: 'Вторая', accusative: 'вторую' },
  { nominative: 'Третья', accusative: 'третью' },
  { nominative: 'Четвертая', accusative: 'четвертую' },
  { nominative: 'Пятая', accusative: 'пятую' },
  { nominative: 'Шестая', accusative: 'шестую' },
  { nominative: 'Седьмая', accusative: 'седьмую' },
  { nominative: 'Восьмая', accusative: 'восьмую' },
  { nominative: 'Девятая', accusative: 'девятую' },
  { nominative: 'Десятая', accusative: 'десятую' },
  { nominative: 'Одиннадцатая', accusative: 'одиннадцатую' },
  { nominative: 'Двенадцатая', accusative: 'двенадцатую' },
  { nominative: 'Тринадцатая', accusative: 'тринадцатую' },
  { nominative: 'Четырнадцатая', accusative: 'четырнадцатую' },
  { nominative: 'Пятнадцатая', accusative: 'пятнадцатую' },
  { nominative: 'Шестнадцатая', accusative: 'шестнадцатую' },
  { nominative: 'Семнадцатая', accusative: 'семнадцатую' },
  { nominative: 'Восемнадцатая', accusative: 'восемнадцатую' },
  { nominative: 'Девятнадцатая', accusative: 'девятнадцатую' },
  { nominative: 'Двадцатая', accusative: 'двадцатую' },
  { nominative: 'Двадцать первая', accusative: 'двадцать первую' },
  { nominative: 'Двадцать вторая', accusative: 'двадцать вторую' },
  { nominative: 'Двадцать третья', accusative: 'двадцать третью' },
  { nominative: 'Двадцать четвертая', accusative: 'двадцать четвертую' },
  { nominative: 'Двадцать пятая', accusative: 'двадцать пятую' },
  { nominative: 'Двадцать шестая', accusative: 'двадцать шестую' },
  { nominative: 'Двадцать седьмая', accusative: 'двадцать седьмую' },
  { nominative: 'Двадцать восьмая', accusative: 'двадцать восьмую' },
  { nominative: 'Двадцать девятая', accusative: 'двадцать девятую' },
  { nominative: 'Тридцатая', accusative: 'тридцатую' },
  { nominative: 'Тридцать первая', accusative: 'тридцать первую' },
  { nominative: 'Тридцать вторая', accusative: 'тридцать вторую' },
  { nominative: 'Тридцать третья', accusative: 'тридцать третью' },
  { nominative: 'Тридцать четвертая', accusative: 'тридцать четвертую' },
  { nominative: 'Тридцать пятая', accusative: 'тридцать пятую' },
  { nominative: 'Тридцать шестая', accusative: 'тридцать шестую' },
  { nominative: 'Тридцать седьмая', accusative: 'тридцать седьмую' },
  { nominative: 'Тридцать восьмая', accusative: 'тридцать восьмую' },
  { nominative: 'Тридцать девятая', accusative: 'тридцать девятую' },
  { nominative: 'Сороковая', accusative: 'сороковую' },
  { nominative: 'Сорок первая', accusative: 'сорок первую' },
  { nominative: 'Сорок вторая', accusative: 'сорок вторую' },
  { nominative: 'Сорок третья', accusative: 'сорок третью' },
  { nominative: 'Сорок четвертая', accusative: 'сорок четвертую' },
  { nominative: 'Сорок пятая', accusative: 'сорок пятую' },
  { nominative: 'Сорок шестая', accusative: 'сорок шестую' },
  { nominative: 'Сорок седьмая', accusative: 'сорок седьмую' },
  { nominative: 'Сорок восьмая', accusative: 'сорок восьмую' },
  { nominative: 'Сорок девятая', accusative: 'сорок девятую' },
  { nominative: 'Пятидесятая', accusative: 'пятидесятую' },
  { nominative: 'Пятьдесят первая', accusative: 'пятьдесят первую' },
  { nominative: 'Пятьдесят вторая', accusative: 'пятьдесят вторую' },
  { nominative: 'Пятьдесят третья', accusative: 'пятьдесят третью' },
  { nominative: 'Пятьдесят четвертая', accusative: 'пятьдесят четвертую' },
  { nominative: 'Пятьдесят пятая', accusative: 'пятьдесят пятую' },
  { nominative: 'Пятьдесят шестая', accusative: 'пятьдесят шестую' },
  { nominative: 'Пятьдесят седьмая', accusative: 'пятьдесят седьмую' },
  { nominative: 'Пятьдесят восьмая', accusative: 'пятьдесят восьмую' },
  { nominative: 'Пятьдесят девятая', accusative: 'пятьдесят девятую' },
  { nominative: 'Шестидесятая', accusative: 'шестидесятую' },
  { nominative: 'Шестьдесят первая', accusative: 'шестьдесят первую' },
  { nominative: 'Шестьдесят вторая', accusative: 'шестьдесят вторую' },
];

export const addDays = (date: Date, counts: number) =>
  new Date(new Date(date).setDate(date.getDate() + counts));

export const addMonths = (date: Date, counts: number) =>
  new Date(new Date(date).setMonth(date.getMonth() + counts));

export const dateToString = (date: Date) => {
  let days: number | string = date.getDate();
  if (days < 10) days = `0${days}`;
  let months: number | string = date.getMonth() + 1;
  if (months < 10) months = `0${months}`;
  return `${date.getFullYear()}-${months}-${days}`;
};

export const stringToTimestamp = (date: string) =>
  new Date(+date.slice(-4), +date.slice(3, 5) - 1, +date.slice(0, 2)).getTime();

export const getFileSize = (size?: number) =>
  size ? (size / 1024 / 1024).toFixed(2) : 0;

export const getClaimDateFormat = (date: string) =>
  `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(0, 4)}`;
