import api from '../http';
import {
  Award,
  AwardToSend,
  Season,
  SeasonExtended,
  SeasonToSend,
} from '../models';

export const seasonService = {
  create: (season: SeasonToSend) =>
    api.post<Season>('playbooks/seasons/', season),
  uploadImage: (formData: any) => api.post('file/upload', formData),
  getAll: (page = 1, size = 10) =>
    api.get<Season[]>(`playbooks/seasons/all?page=${page}&size=${size}`),
  createAward: (award: AwardToSend, playbookId: number) =>
    api.post<Award>(`playbooks/seasons/award/playbookId=${playbookId}`, award),
  getByPlaybookId: (playbookId: number) =>
    api.get<SeasonExtended>(`playbooks/seasons/playbookId=${playbookId}`),
  start: (playbookId: number) =>
    api.put(`playbooks/seasons/start/playbookId=${playbookId}`),
  delete: (playbookId: number) =>
    api.delete(`playbooks/seasons/playbookId=${playbookId}`),
  stop: (playbookId: number) =>
    api.put(`playbooks/seasons/stop/playbookId=${playbookId}`),
};
