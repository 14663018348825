import styled from 'styled-components';

type Props = {
  currentXP: string;
  levelXP: string;
  bg?: string;
  small?: boolean;
};

const StyledIndicator = styled.div`
  height: ${(props: Props) => (props.small ? '2px' : '4px')};
  background: ${(props) => props.bg ?? props.theme.colors.grayBorderButton};
  margin-bottom: ${(props: Props) => (props.small ? 0 : '6px')};
  position: relative;
  border-radius: 10px;
`;

const IndicatorLabel = styled.div`
  width: ${(props: Props) => `${(+props.currentXP / +props.levelXP) * 100}%;`};
  background: ${(props) => props.theme.colors.greenBg};
  height: 100%;
  border-radius: 10px;
`;

export const Indicator = (props: Props) => (
  <StyledIndicator {...props}>
    <IndicatorLabel {...props} />
  </StyledIndicator>
);
