import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../assets/icons';
import { ConfirmButton, PageHeader } from '../components';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { taskService } from '../services';
import { UpdateTask } from '../models';
import { Warning } from '../components/common/Warning';

const StyledClaimsWrapper = styled.div`
  margin: 10px 32px;
  width: 100%;
`;

const StyledSearchField = styled.div`
  position: relative;
  max-width: 360px;
  margin-bottom: 16px;
  & > svg {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  border-radius: 8px;
  tr {
    color: ${(props) => props.theme.colors.graySubtitle};
    background-color: ${(props) => props.theme.colors.grayBgButton};
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
      min-width: 180px;
    }
  }
`;

const Tbody = styled.tbody`
  &:before {
    content: '@';
    display: block;
    line-height: 6px;
    text-indent: -99999px;
  }
  tr:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.grayBgButton};
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 12px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 14px 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const SearchInput = styled.input`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  color: ${(props) => props.theme.colors.graySubtitle};
  border: 1px solid ${(props) => props.theme.colors.grayBgButton};
  line-height: 22px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 45px 8px 16px;
  outline: none;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.greenBg};
  }
`;

const columns = [
  {
    field: 'playgroundId',
    label: 'Номер задания',
    controls: false,
  },
  {
    field: 'actionCount',
    label: 'Кол-во действий',
    controls: false,
  },
  {
    field: 'description',
    label: 'Описание',
    controls: false,
  },
  {
    field: 'duration',
    label: 'Продолжительность',
    controls: false,
  },
  {
    field: 'product',
    label: 'Продукт',
    controls: false,
  },
  {
    field: 'activate',
    label: '',
    controls: false,
  },
];

export const TaskActivationPage = () => {
  const [search, setSearch] = useState('');
  const [tasks, setTasks] = useState<UpdateTask[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    taskService.getAll().then(({ data }) => {
      setTasks(data.filter((d) => !d.isActive));
    });
  }, []);

  const filteredAndSortedClaims = useCallback(() => {
    const filtered = tasks.filter((el) =>
      String(el.playbookId).includes(search)
    );
    return filtered;
  }, [search, tasks]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleTaskActivate = (id: number) =>
    new Promise<void>((resolve) => {
      const task = tasks.find((t) => t.playbookId === id);

      if (task) {
        const {
          actionId,
          playbookId,
          product: { id: productId },
          elementId,
        } = task;

        taskService
          .activate({
            actionId,
            playbookId,
            productId,
            taskId: elementId,
            isActive: true,
          })
          .then((response) => {
            if (response.status === 200) {
              setTasks((prevData) =>
                prevData.filter((t) => t.playbookId !== id)
              );
              resolve();
            }
          });
      }
    });

  return (
    <StyledClaimsWrapper>
      <Warning right />
      <PageHeader
        onClick={() => navigate('/gamification')}
        title="Активация заданий"
      />
      <StyledSearchField>
        <SearchInput
          type="text"
          placeholder="Поиск по заданиям"
          value={search}
          onChange={handleSearchChange}
        />
        <SearchIcon />
      </StyledSearchField>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th key={uuidv4()}>
                <div>{column.label}</div>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {filteredAndSortedClaims().map((task) => (
            <Tr key={uuidv4()}>
              <Td>{`№${task.playbookId}`}</Td>
              <Td>{task.actionsCount}</Td>
              <Td>{task.description}</Td>
              <Td>{task.duration}</Td>
              <Td>{task.product.name}</Td>
              <Td>
                <ConfirmButton
                  onClick={() => handleTaskActivate(task.playbookId)}
                  text="Активировать"
                  modalMessage="Задание активировано"
                  modalBtnText="Хорошо"
                  disable={false}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <QuestionLabel />
    </StyledClaimsWrapper>
  );
};
